import { ACCESS_TOKEN_INDEX_NAME, useAuthStore } from "~/store/auth";
// import { useOneSignalStore } from "~/store/onesignal";
import { useProfileStore } from "~/store/profile";

export default defineNuxtRouteMiddleware(async (to, from) => {
    await handleExistingAuthIfExist()
})

const handleExistingAuthIfExist = async () => {
    const accessToken = useCookie(ACCESS_TOKEN_INDEX_NAME);
    if(accessToken.value){
        useProfileStore().retrieveIdentity()
        .then(async (profileData) => {
            useAuthStore().authenticated = true
            // await useOneSignalStore().login()
        })
        .catch(async err => {
            useAuthStore().authenticated = false
            // await useOneSignalStore().logout()
            accessToken.value = null
        })
    }
}