import Big from 'big.js';
import { toast } from 'vue3-toastify';
import { AddToCartRequestDto, CartResponseDto } from '~/contract/dto/CartRelated.dto';
import { CartItemType } from '~/contract/enum/CartRelated.enum';
import { CartPayload } from '~/contract/vo/CartRelated.vo';
import ApiService from '~/service/ApiService';
import axiosData from '../static/data/cart.json';
import axiosAllProucts from '../static/data/products.json';
import { useAuthStore } from './auth';

export const useCartStore = defineStore({
  id: 'cart',
  state: () => {
    return {
      allProducts: axiosAllProucts.data,
      data: axiosData,
      cartItems: [] as Array<CartResponseDto>,
      cartCount: 0 as number,
      order: [],
      lastAddedProduct1: 0,
      userInfo: {},
    };
  },
  getters: {
    cartItemsNonPreorder(state) {
      return state.cartItems.filter((item) => item.cartItemType === CartItemType.NORMAL);
    },
    cartItemsPreorder(state) {
      return state.cartItems.filter((item) => item.cartItemType === CartItemType.PREORDER);
    },
    cartTotal(state) {
      let total = Big(0);
      state.cartItems.forEach((item) => {
        let itemTotal = Big(item.productPrice).mul(Big(item.quantity));
        total = total.add(itemTotal);
      });
      return total.round(0, Big.roundHalfUp).toNumber();
    },
    cartTotalPreorder(state) {
      let total = Big(0);
      this.cartItemsPreorder.forEach((item) => {
        let itemTotal = Big(item.productPrice).mul(Big(item.quantity));
        total = total.add(itemTotal);
      });
      return total.round(0, Big.roundHalfUp).toNumber();
    },
    cartTotalNonPreorder(state) {
      let total = Big(0);
      this.cartItemsNonPreorder.forEach((item) => {
        let itemTotal = Big(item.productPrice).mul(Big(item.quantity));
        total = total.add(itemTotal);
      });
      return total.round(0, Big.roundHalfUp).toNumber();
    },
    checkoutTotalPreorder(state) {
      let total = Big(0);
      this.cartItemsPreorder.forEach((item) => {
        let itemTotal = Big(item.downPaymentItemPrice).mul(Big(item.quantity));
        total = total.add(itemTotal);
      });
      return total.round(0, Big.roundHalfUp).toNumber();
    },
    cartItemCount(state) {
      return state.cartItems.reduce((a, b) => {
        return a + b.quantity;
      }, 0);
    },
    cartItemCountPreorder(state): number {
      return this.cartItemsPreorder.reduce((a, b) => {
        return a + b.quantity;
      }, 0);
    },
    cartItemCountNonPreorder(state): number {
      return this.cartItemsNonPreorder.reduce((a, b) => {
        return a + b.quantity;
      }, 0);
    },
    inCartProductIds(state) {
      let cartProductIds = [];
      state.cartItems.forEach((item) => {
        cartProductIds.push(item.id);
      });
      return cartProductIds;
    },
    getCartItems(state) {
      return state.cartItems;
    },
    lastAddedProduct(state) {
      if (state.lastAddedProduct1 === 0) return state.allProducts[0];
      else return state.lastAddedProduct1;
    },
    getOrder: (state) => {
      return state.order;
    },
  },
  actions: {
    async fetchCartList() {
      if (!useAuthStore().authenticated) return;
      const { data, pending, error }: any = await ApiService.get('/api/v1', 'cart', true);
      if (error.value) {
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<CartResponseDto>;
        this.cartItems = response;
      }
    },
    addToCart(payload) {
      var itemToAdd: any = {};
      var productIndex = indexFound(this.cartItems, payload);
      if (productIndex !== false) {
        this.cartItems[productIndex].quantity += payload.quantity;
        this.lastAddedProduct1 = this.cartItems[productIndex];
      } else {
        var itemFound = false;
        this.allProducts.every((item) => {
          if (item.id === payload.product.id) {
            itemToAdd = payload.product;
            itemToAdd['quantity'] = payload.quantity;
            itemFound = true;
            return false;
          } else return true;
        });
        if (itemFound) {
          this.cartItems.push(itemToAdd);
          this.lastAddedProduct1 = itemToAdd;
        } else alert('item not in JSON');
      }
    },
    async addProductToCart(request: AddToCartRequestDto) {
      if (!useAuthStore().authenticated) return useRouter().push('/auth/login');
      const { data, pending, error }: any = await ApiService.post(`/api/v1/cart`, request, true);
      if (error.value) {
        throw error.value;
      } else {
        toast.success(data.value.message, {
          position: 'top-right',
        });
      }
      await this.fetchCartList();
    },
    async transferProductFromWishlist(wishlistId: string) {
      if (!useAuthStore().authenticated) return useRouter().push('/auth/login');
      const { data, pending, error }: any = await ApiService.post(`/api/v1/cart/transfer/${wishlistId}`, {}, true);
      if (error.value) {
        throw error.value;
      } else {
        toast.success(data.value.message, {
          position: 'top-right',
        });
      }
      await this.fetchCartList();
    },
    async changeQuantityTo(payload: CartPayload) {
      const { productId, diffQty } = payload;
      const selectedItem = this.cartItems.filter((item) => {
        if (item.id == productId) {
          return item;
        }
      });
      const baseQty: number = selectedItem.length > 0 ? selectedItem[0].quantity : 0;
      const actualDiffQty: number = Math.floor(diffQty) - Math.floor(baseQty);
      const { data, pending, error }: any = await ApiService.patch(`/api/v1/cart/${productId}`, { diffQty: actualDiffQty as number }, true);
      if (error.value) {
        throw error.value;
      }
      await this.fetchCartList();
    },
    clearAllCartItems() {
      this.cartItems.forEach((e) => {
        this.removeCartItem(e.id);
      });
    },
    clearAllCartItemsNonPreorder() {
      this.cartItems
        .filter((item) => item.cartItemType === CartItemType.NORMAL)
        .forEach((item) => {
          this.removeCartItem(item.id);
        });
    },
    clearAllCartItemsPreorder() {
      this.cartItems
        .filter((item) => item.cartItemType === CartItemType.PREORDER)
        .forEach((item) => {
          this.removeCartItem(item.id);
        });
    },
    async removeCartItem(payload) {
      const { data, pending, error }: any = await ApiService.delete(`/api/v1/cart/${payload}`, true);
      if (error.value) {
        throw error.value;
      }

      toast.success(data.value.message, {
        position: 'top-right',
      });
      await this.fetchCartList();
    },
    createOrder(payload) {
      this.order.push(payload);
    },
    saveUserInfo(payload) {
      this.userInfo = payload;
    },
    setLocalCart(payload) {
      Array.isArray(payload) && payload.length && (this.cartItems = payload);
    },
  },
});

function indexFound(cartItems, itemToAdd) {
  let isPresent = false;
  cartItems.every((product, index) => {
    if (product.id === itemToAdd.product.id) {
      isPresent = index;
      return false;
    } else {
      return true;
    }
  });
  return isPresent;
}
