import { default as _91_46_46_46all_93nxaKU5yVlcMeta } from "/builds/vapebay/commerce-frontend/pages/[...all].vue?macro=true";
import { default as indexZhkNPCDtvxMeta } from "/builds/vapebay/commerce-frontend/pages/about_us/index.vue?macro=true";
import { default as finish_registrationKw1Fh7BDteMeta } from "/builds/vapebay/commerce-frontend/pages/auth/finish_registration.vue?macro=true";
import { default as forgot_passwordVsFwKccxlrMeta } from "/builds/vapebay/commerce-frontend/pages/auth/forgot_password.vue?macro=true";
import { default as loginmUuHhAwRSUMeta } from "/builds/vapebay/commerce-frontend/pages/auth/login.vue?macro=true";
import { default as logoutAhnCBLrLm9Meta } from "/builds/vapebay/commerce-frontend/pages/auth/logout.vue?macro=true";
import { default as registerIXuS0LKHAwMeta } from "/builds/vapebay/commerce-frontend/pages/auth/register.vue?macro=true";
import { default as _91type_93jD0OlCtbtHMeta } from "/builds/vapebay/commerce-frontend/pages/auth/social/[type].vue?macro=true";
import { default as indexKZlZKKqNviMeta } from "/builds/vapebay/commerce-frontend/pages/cart/index.vue?macro=true";
import { default as indexedJLjQs4pKMeta } from "/builds/vapebay/commerce-frontend/pages/categories/index.vue?macro=true";
import { default as indexziEq5kir21Meta } from "/builds/vapebay/commerce-frontend/pages/checkout_preorder/index.vue?macro=true";
import { default as indexo0QM2qjUsYMeta } from "/builds/vapebay/commerce-frontend/pages/checkout/index.vue?macro=true";
import { default as indexutblo6MGbOMeta } from "/builds/vapebay/commerce-frontend/pages/faq/index.vue?macro=true";
import { default as index3dtLRQKAAbMeta } from "/builds/vapebay/commerce-frontend/pages/index.vue?macro=true";
import { default as fulfillolUsEkssYsMeta } from "/builds/vapebay/commerce-frontend/pages/preorder/[id]/fulfill.vue?macro=true";
import { default as indexApsrTloH1GMeta } from "/builds/vapebay/commerce-frontend/pages/preorder/[id]/index.vue?macro=true";
import { default as indexD0Zj7mOcTXMeta } from "/builds/vapebay/commerce-frontend/pages/preorder/index.vue?macro=true";
import { default as indexYwOAHlBUkSMeta } from "/builds/vapebay/commerce-frontend/pages/privacy_and_policy/index.vue?macro=true";
import { default as _91id_93cT3oJKPuvuMeta } from "/builds/vapebay/commerce-frontend/pages/product/[id].vue?macro=true";
import { default as _91id_93BciinTLp9LMeta } from "/builds/vapebay/commerce-frontend/pages/profile/address/[id].vue?macro=true";
import { default as create3O3PXgId1NMeta } from "/builds/vapebay/commerce-frontend/pages/profile/address/create.vue?macro=true";
import { default as index4zLovjUJZ7Meta } from "/builds/vapebay/commerce-frontend/pages/profile/address/index.vue?macro=true";
import { default as _91id_93BOJ4CQb13iMeta } from "/builds/vapebay/commerce-frontend/pages/profile/bankaccount/[id].vue?macro=true";
import { default as createwDdYh0CybpMeta } from "/builds/vapebay/commerce-frontend/pages/profile/bankaccount/create.vue?macro=true";
import { default as indexQVbNTm1GROMeta } from "/builds/vapebay/commerce-frontend/pages/profile/bankaccount/index.vue?macro=true";
import { default as indexNRWWrxCwWQMeta } from "/builds/vapebay/commerce-frontend/pages/profile/index.vue?macro=true";
import { default as indextQ2M6oEwRDMeta } from "/builds/vapebay/commerce-frontend/pages/profile/info/edit_profile/index.vue?macro=true";
import { default as indexxIyiqH8aoHMeta } from "/builds/vapebay/commerce-frontend/pages/profile/info/index.vue?macro=true";
import { default as securityR1pyIGkzOKMeta } from "/builds/vapebay/commerce-frontend/pages/profile/security.vue?macro=true";
import { default as _91id_939e4r1Vwbw9Meta } from "/builds/vapebay/commerce-frontend/pages/profile/voucher/[id].vue?macro=true";
import { default as index4bbWhcxyPoMeta } from "/builds/vapebay/commerce-frontend/pages/profile/voucher/index.vue?macro=true";
import { default as indexxZyOwuDwuMMeta } from "/builds/vapebay/commerce-frontend/pages/profile/wallet/index.vue?macro=true";
import { default as createiCDsYhMX7gMeta } from "/builds/vapebay/commerce-frontend/pages/profile/withdraw/create.vue?macro=true";
import { default as index9b6lBVsSfdMeta } from "/builds/vapebay/commerce-frontend/pages/profile/withdraw/index.vue?macro=true";
import { default as actiondSA3eeZFslMeta } from "/builds/vapebay/commerce-frontend/pages/reset/[id]/action.vue?macro=true";
import { default as indexdmCLYFlQrMMeta } from "/builds/vapebay/commerce-frontend/pages/reset/[id]/index.vue?macro=true";
import { default as index00auIidTCZMeta } from "/builds/vapebay/commerce-frontend/pages/search/index.vue?macro=true";
import { default as indexKhFs1zbkVvMeta } from "/builds/vapebay/commerce-frontend/pages/terms_of_service/index.vue?macro=true";
import { default as _91id_93Rn6ujoUwC2Meta } from "/builds/vapebay/commerce-frontend/pages/transaction/[id].vue?macro=true";
import { default as indexIbwBtUA5osMeta } from "/builds/vapebay/commerce-frontend/pages/transaction/index.vue?macro=true";
import { default as _91id_93r7MLiGamrHMeta } from "/builds/vapebay/commerce-frontend/pages/verify/email/[id].vue?macro=true";
import { default as _91id_93TI7eyKb4doMeta } from "/builds/vapebay/commerce-frontend/pages/voucher/[id].vue?macro=true";
import { default as index8hfHUyAkOaMeta } from "/builds/vapebay/commerce-frontend/pages/voucher/index.vue?macro=true";
import { default as indexVFtPOPORXrMeta } from "/builds/vapebay/commerce-frontend/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93nxaKU5yVlcMeta?.name ?? "all",
    path: _91_46_46_46all_93nxaKU5yVlcMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93nxaKU5yVlcMeta || {},
    alias: _91_46_46_46all_93nxaKU5yVlcMeta?.alias || [],
    redirect: _91_46_46_46all_93nxaKU5yVlcMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: indexZhkNPCDtvxMeta?.name ?? "about_us",
    path: indexZhkNPCDtvxMeta?.path ?? "/about_us",
    meta: indexZhkNPCDtvxMeta || {},
    alias: indexZhkNPCDtvxMeta?.alias || [],
    redirect: indexZhkNPCDtvxMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/about_us/index.vue").then(m => m.default || m)
  },
  {
    name: finish_registrationKw1Fh7BDteMeta?.name ?? "auth-finish_registration",
    path: finish_registrationKw1Fh7BDteMeta?.path ?? "/auth/finish_registration",
    meta: finish_registrationKw1Fh7BDteMeta || {},
    alias: finish_registrationKw1Fh7BDteMeta?.alias || [],
    redirect: finish_registrationKw1Fh7BDteMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/auth/finish_registration.vue").then(m => m.default || m)
  },
  {
    name: forgot_passwordVsFwKccxlrMeta?.name ?? "auth-forgot_password",
    path: forgot_passwordVsFwKccxlrMeta?.path ?? "/auth/forgot_password",
    meta: forgot_passwordVsFwKccxlrMeta || {},
    alias: forgot_passwordVsFwKccxlrMeta?.alias || [],
    redirect: forgot_passwordVsFwKccxlrMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/auth/forgot_password.vue").then(m => m.default || m)
  },
  {
    name: loginmUuHhAwRSUMeta?.name ?? "auth-login",
    path: loginmUuHhAwRSUMeta?.path ?? "/auth/login",
    meta: loginmUuHhAwRSUMeta || {},
    alias: loginmUuHhAwRSUMeta?.alias || [],
    redirect: loginmUuHhAwRSUMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logoutAhnCBLrLm9Meta?.name ?? "auth-logout",
    path: logoutAhnCBLrLm9Meta?.path ?? "/auth/logout",
    meta: logoutAhnCBLrLm9Meta || {},
    alias: logoutAhnCBLrLm9Meta?.alias || [],
    redirect: logoutAhnCBLrLm9Meta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: registerIXuS0LKHAwMeta?.name ?? "auth-register",
    path: registerIXuS0LKHAwMeta?.path ?? "/auth/register",
    meta: registerIXuS0LKHAwMeta || {},
    alias: registerIXuS0LKHAwMeta?.alias || [],
    redirect: registerIXuS0LKHAwMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91type_93jD0OlCtbtHMeta?.name ?? "auth-social-type",
    path: _91type_93jD0OlCtbtHMeta?.path ?? "/auth/social/:type()",
    meta: _91type_93jD0OlCtbtHMeta || {},
    alias: _91type_93jD0OlCtbtHMeta?.alias || [],
    redirect: _91type_93jD0OlCtbtHMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/auth/social/[type].vue").then(m => m.default || m)
  },
  {
    name: indexKZlZKKqNviMeta?.name ?? "cart",
    path: indexKZlZKKqNviMeta?.path ?? "/cart",
    meta: indexKZlZKKqNviMeta || {},
    alias: indexKZlZKKqNviMeta?.alias || [],
    redirect: indexKZlZKKqNviMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexedJLjQs4pKMeta?.name ?? "categories",
    path: indexedJLjQs4pKMeta?.path ?? "/categories",
    meta: indexedJLjQs4pKMeta || {},
    alias: indexedJLjQs4pKMeta?.alias || [],
    redirect: indexedJLjQs4pKMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexziEq5kir21Meta?.name ?? "checkout_preorder",
    path: indexziEq5kir21Meta?.path ?? "/checkout_preorder",
    meta: indexziEq5kir21Meta || {},
    alias: indexziEq5kir21Meta?.alias || [],
    redirect: indexziEq5kir21Meta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/checkout_preorder/index.vue").then(m => m.default || m)
  },
  {
    name: indexo0QM2qjUsYMeta?.name ?? "checkout",
    path: indexo0QM2qjUsYMeta?.path ?? "/checkout",
    meta: indexo0QM2qjUsYMeta || {},
    alias: indexo0QM2qjUsYMeta?.alias || [],
    redirect: indexo0QM2qjUsYMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexutblo6MGbOMeta?.name ?? "faq",
    path: indexutblo6MGbOMeta?.path ?? "/faq",
    meta: indexutblo6MGbOMeta || {},
    alias: indexutblo6MGbOMeta?.alias || [],
    redirect: indexutblo6MGbOMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index3dtLRQKAAbMeta?.name ?? "index",
    path: index3dtLRQKAAbMeta?.path ?? "/",
    meta: index3dtLRQKAAbMeta || {},
    alias: index3dtLRQKAAbMeta?.alias || [],
    redirect: index3dtLRQKAAbMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: fulfillolUsEkssYsMeta?.name ?? "preorder-id-fulfill",
    path: fulfillolUsEkssYsMeta?.path ?? "/preorder/:id()/fulfill",
    meta: fulfillolUsEkssYsMeta || {},
    alias: fulfillolUsEkssYsMeta?.alias || [],
    redirect: fulfillolUsEkssYsMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/preorder/[id]/fulfill.vue").then(m => m.default || m)
  },
  {
    name: indexApsrTloH1GMeta?.name ?? "preorder-id",
    path: indexApsrTloH1GMeta?.path ?? "/preorder/:id()",
    meta: indexApsrTloH1GMeta || {},
    alias: indexApsrTloH1GMeta?.alias || [],
    redirect: indexApsrTloH1GMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/preorder/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexD0Zj7mOcTXMeta?.name ?? "preorder",
    path: indexD0Zj7mOcTXMeta?.path ?? "/preorder",
    meta: indexD0Zj7mOcTXMeta || {},
    alias: indexD0Zj7mOcTXMeta?.alias || [],
    redirect: indexD0Zj7mOcTXMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/preorder/index.vue").then(m => m.default || m)
  },
  {
    name: indexYwOAHlBUkSMeta?.name ?? "privacy_and_policy",
    path: indexYwOAHlBUkSMeta?.path ?? "/privacy_and_policy",
    meta: indexYwOAHlBUkSMeta || {},
    alias: indexYwOAHlBUkSMeta?.alias || [],
    redirect: indexYwOAHlBUkSMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/privacy_and_policy/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cT3oJKPuvuMeta?.name ?? "product-id",
    path: _91id_93cT3oJKPuvuMeta?.path ?? "/product/:id()",
    meta: _91id_93cT3oJKPuvuMeta || {},
    alias: _91id_93cT3oJKPuvuMeta?.alias || [],
    redirect: _91id_93cT3oJKPuvuMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93BciinTLp9LMeta?.name ?? "profile-address-id",
    path: _91id_93BciinTLp9LMeta?.path ?? "/profile/address/:id()",
    meta: _91id_93BciinTLp9LMeta || {},
    alias: _91id_93BciinTLp9LMeta?.alias || [],
    redirect: _91id_93BciinTLp9LMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/address/[id].vue").then(m => m.default || m)
  },
  {
    name: create3O3PXgId1NMeta?.name ?? "profile-address-create",
    path: create3O3PXgId1NMeta?.path ?? "/profile/address/create",
    meta: create3O3PXgId1NMeta || {},
    alias: create3O3PXgId1NMeta?.alias || [],
    redirect: create3O3PXgId1NMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/address/create.vue").then(m => m.default || m)
  },
  {
    name: index4zLovjUJZ7Meta?.name ?? "profile-address",
    path: index4zLovjUJZ7Meta?.path ?? "/profile/address",
    meta: index4zLovjUJZ7Meta || {},
    alias: index4zLovjUJZ7Meta?.alias || [],
    redirect: index4zLovjUJZ7Meta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/address/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BOJ4CQb13iMeta?.name ?? "profile-bankaccount-id",
    path: _91id_93BOJ4CQb13iMeta?.path ?? "/profile/bankaccount/:id()",
    meta: _91id_93BOJ4CQb13iMeta || {},
    alias: _91id_93BOJ4CQb13iMeta?.alias || [],
    redirect: _91id_93BOJ4CQb13iMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/bankaccount/[id].vue").then(m => m.default || m)
  },
  {
    name: createwDdYh0CybpMeta?.name ?? "profile-bankaccount-create",
    path: createwDdYh0CybpMeta?.path ?? "/profile/bankaccount/create",
    meta: createwDdYh0CybpMeta || {},
    alias: createwDdYh0CybpMeta?.alias || [],
    redirect: createwDdYh0CybpMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/bankaccount/create.vue").then(m => m.default || m)
  },
  {
    name: indexQVbNTm1GROMeta?.name ?? "profile-bankaccount",
    path: indexQVbNTm1GROMeta?.path ?? "/profile/bankaccount",
    meta: indexQVbNTm1GROMeta || {},
    alias: indexQVbNTm1GROMeta?.alias || [],
    redirect: indexQVbNTm1GROMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/bankaccount/index.vue").then(m => m.default || m)
  },
  {
    name: indexNRWWrxCwWQMeta?.name ?? "profile",
    path: indexNRWWrxCwWQMeta?.path ?? "/profile",
    meta: indexNRWWrxCwWQMeta || {},
    alias: indexNRWWrxCwWQMeta?.alias || [],
    redirect: indexNRWWrxCwWQMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indextQ2M6oEwRDMeta?.name ?? "profile-info-edit_profile",
    path: indextQ2M6oEwRDMeta?.path ?? "/profile/info/edit_profile",
    meta: indextQ2M6oEwRDMeta || {},
    alias: indextQ2M6oEwRDMeta?.alias || [],
    redirect: indextQ2M6oEwRDMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/info/edit_profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexxIyiqH8aoHMeta?.name ?? "profile-info",
    path: indexxIyiqH8aoHMeta?.path ?? "/profile/info",
    meta: indexxIyiqH8aoHMeta || {},
    alias: indexxIyiqH8aoHMeta?.alias || [],
    redirect: indexxIyiqH8aoHMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/info/index.vue").then(m => m.default || m)
  },
  {
    name: securityR1pyIGkzOKMeta?.name ?? "profile-security",
    path: securityR1pyIGkzOKMeta?.path ?? "/profile/security",
    meta: securityR1pyIGkzOKMeta || {},
    alias: securityR1pyIGkzOKMeta?.alias || [],
    redirect: securityR1pyIGkzOKMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/security.vue").then(m => m.default || m)
  },
  {
    name: _91id_939e4r1Vwbw9Meta?.name ?? "profile-voucher-id",
    path: _91id_939e4r1Vwbw9Meta?.path ?? "/profile/voucher/:id()",
    meta: _91id_939e4r1Vwbw9Meta || {},
    alias: _91id_939e4r1Vwbw9Meta?.alias || [],
    redirect: _91id_939e4r1Vwbw9Meta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/voucher/[id].vue").then(m => m.default || m)
  },
  {
    name: index4bbWhcxyPoMeta?.name ?? "profile-voucher",
    path: index4bbWhcxyPoMeta?.path ?? "/profile/voucher",
    meta: index4bbWhcxyPoMeta || {},
    alias: index4bbWhcxyPoMeta?.alias || [],
    redirect: index4bbWhcxyPoMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/voucher/index.vue").then(m => m.default || m)
  },
  {
    name: indexxZyOwuDwuMMeta?.name ?? "profile-wallet",
    path: indexxZyOwuDwuMMeta?.path ?? "/profile/wallet",
    meta: indexxZyOwuDwuMMeta || {},
    alias: indexxZyOwuDwuMMeta?.alias || [],
    redirect: indexxZyOwuDwuMMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: createiCDsYhMX7gMeta?.name ?? "profile-withdraw-create",
    path: createiCDsYhMX7gMeta?.path ?? "/profile/withdraw/create",
    meta: createiCDsYhMX7gMeta || {},
    alias: createiCDsYhMX7gMeta?.alias || [],
    redirect: createiCDsYhMX7gMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/withdraw/create.vue").then(m => m.default || m)
  },
  {
    name: index9b6lBVsSfdMeta?.name ?? "profile-withdraw",
    path: index9b6lBVsSfdMeta?.path ?? "/profile/withdraw",
    meta: index9b6lBVsSfdMeta || {},
    alias: index9b6lBVsSfdMeta?.alias || [],
    redirect: index9b6lBVsSfdMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/profile/withdraw/index.vue").then(m => m.default || m)
  },
  {
    name: actiondSA3eeZFslMeta?.name ?? "reset-id-action",
    path: actiondSA3eeZFslMeta?.path ?? "/reset/:id()/action",
    meta: actiondSA3eeZFslMeta || {},
    alias: actiondSA3eeZFslMeta?.alias || [],
    redirect: actiondSA3eeZFslMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/reset/[id]/action.vue").then(m => m.default || m)
  },
  {
    name: indexdmCLYFlQrMMeta?.name ?? "reset-id",
    path: indexdmCLYFlQrMMeta?.path ?? "/reset/:id()",
    meta: indexdmCLYFlQrMMeta || {},
    alias: indexdmCLYFlQrMMeta?.alias || [],
    redirect: indexdmCLYFlQrMMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/reset/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index00auIidTCZMeta?.name ?? "search",
    path: index00auIidTCZMeta?.path ?? "/search",
    meta: index00auIidTCZMeta || {},
    alias: index00auIidTCZMeta?.alias || [],
    redirect: index00auIidTCZMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexKhFs1zbkVvMeta?.name ?? "terms_of_service",
    path: indexKhFs1zbkVvMeta?.path ?? "/terms_of_service",
    meta: indexKhFs1zbkVvMeta || {},
    alias: indexKhFs1zbkVvMeta?.alias || [],
    redirect: indexKhFs1zbkVvMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/terms_of_service/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Rn6ujoUwC2Meta?.name ?? "transaction-id",
    path: _91id_93Rn6ujoUwC2Meta?.path ?? "/transaction/:id()",
    meta: _91id_93Rn6ujoUwC2Meta || {},
    alias: _91id_93Rn6ujoUwC2Meta?.alias || [],
    redirect: _91id_93Rn6ujoUwC2Meta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/transaction/[id].vue").then(m => m.default || m)
  },
  {
    name: indexIbwBtUA5osMeta?.name ?? "transaction",
    path: indexIbwBtUA5osMeta?.path ?? "/transaction",
    meta: indexIbwBtUA5osMeta || {},
    alias: indexIbwBtUA5osMeta?.alias || [],
    redirect: indexIbwBtUA5osMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/transaction/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93r7MLiGamrHMeta?.name ?? "verify-email-id",
    path: _91id_93r7MLiGamrHMeta?.path ?? "/verify/email/:id()",
    meta: _91id_93r7MLiGamrHMeta || {},
    alias: _91id_93r7MLiGamrHMeta?.alias || [],
    redirect: _91id_93r7MLiGamrHMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/verify/email/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TI7eyKb4doMeta?.name ?? "voucher-id",
    path: _91id_93TI7eyKb4doMeta?.path ?? "/voucher/:id()",
    meta: _91id_93TI7eyKb4doMeta || {},
    alias: _91id_93TI7eyKb4doMeta?.alias || [],
    redirect: _91id_93TI7eyKb4doMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/voucher/[id].vue").then(m => m.default || m)
  },
  {
    name: index8hfHUyAkOaMeta?.name ?? "voucher",
    path: index8hfHUyAkOaMeta?.path ?? "/voucher",
    meta: index8hfHUyAkOaMeta || {},
    alias: index8hfHUyAkOaMeta?.alias || [],
    redirect: index8hfHUyAkOaMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/voucher/index.vue").then(m => m.default || m)
  },
  {
    name: indexVFtPOPORXrMeta?.name ?? "wishlist",
    path: indexVFtPOPORXrMeta?.path ?? "/wishlist",
    meta: indexVFtPOPORXrMeta || {},
    alias: indexVFtPOPORXrMeta?.alias || [],
    redirect: indexVFtPOPORXrMeta?.redirect || undefined,
    component: () => import("/builds/vapebay/commerce-frontend/pages/wishlist/index.vue").then(m => m.default || m)
  }
]