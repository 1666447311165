export enum ConstantKey {
  COMPANY_ADDRESS = "COMPANY_ADDRESS",
  COMPANY_PHONE = "COMPANY_PHONE",
  COMPANY_EMAIL = "COMPANY_EMAIL",
  COMPANY_TIKTOK = "COMPANY_TIKTOK",
  COMPANY_INSTAGRAM = "COMPANY_INSTAGRAM",
  COMPANY_ABOUT = "COMPANY_ABOUT",
  COMPANY_TOS = "COMPANY_TOS",
  COMPANY_FAQ = "COMPANY_FAQ",
  COMPANY_PRIVACY = "COMPANY_PRIVACY",
}
