import { ProductItem } from "~/contract/vo/ProductRelated.vo";
import ApiService from "~/service/ApiService";
import { useAuthStore } from "./auth";
import { ProductVariantOptionItem } from "~/contract/vo/ProductVariantRelated.vo";

export const useProductCustomStore = defineStore("productCustom", {
  state: () => ({
    product: null as unknown as ProductItem,
    recommendedProduct: [] as Array<ProductItem>,
    latestProduct: [] as Array<ProductItem>,
    selectedVariantOption: null as unknown as ProductVariantOptionItem,
    productLoading: false,
    recommendedProductLoading: false,
    latestProductLoading: false,
  }),
  actions: {
    async retrieveProduct(urlSlug: string) {
      this.productLoading = true;
      const { data, pending, error }: any = useAuthStore().authenticated
        ? await ApiService.get("/api/v1/product", urlSlug, false)
        : await ApiService.get("/api/v1/product/public", urlSlug, false);
      this.productLoading = pending.value;
      if (error.value) {
        this.productLoading = true;
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as ProductItem;
        this.product = response;
      }
    },
    async retrieveRecommendedProduct(id: string) {
      this.recommendedProductLoading = true;
      const { data, pending, error }: any = useAuthStore().authenticated
        ? await ApiService.get("/api/v1/product/recommend", id, true)
        : await ApiService.get("/api/v1/product/public/recommend", id, true);
      this.recommendedProductLoading = pending.value;
      if (error.value) {
        this.recommendedProductLoading = true;
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<ProductItem>;
        this.recommendedProduct = response;
      }
    },
    async retrieveLatestProduct() {
      this.latestProductLoading = true;
      const { data, pending, error }: any = useAuthStore().authenticated
        ? await ApiService.query("/api/v1/product/latest", {}, true)
        : await ApiService.query("/api/v1/product/public/latest", {}, true);
      this.latestProductLoading = pending.value;
      if (error.value) {
        this.latestProductLoading = true;
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<ProductItem>;
        this.latestProduct = response;
      }
    },
  },
});
