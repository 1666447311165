import { defineStore } from "pinia";
import ApiService from "~/service/ApiService";
import { useAuthStore } from "./auth";
import { FlashsaleResponse } from "~/contract/dto/FlashsaleRelated.dto";

// Define the ProductItem type
interface ProductItem {
  // Add the properties of the product item here
}

export const useFlashsaleStore = defineStore({
  id: "flashsaleStore",
  state: () => {
    return {
      flashsaleData: {} as FlashsaleResponse | null,
      flashsaleLoading: true as boolean,
      flashsaleProducts: [] as Array<ProductItem>,
      startTime: new Date() as Date,
      endTime: new Date() as Date,
    };
  },
  actions: {
    async fetchFlashsaleData(urlSlug: string) {
      const { data, pending, error }: any = await ApiService.get(
        "/api/v1/flashsale",
        urlSlug,
        false
      );
      if (error.value) {
        throw error.value;
      }
      if (data.value) {
        const response = data.value.data as FlashsaleResponse | null;
        if (response) {
          this.startTime = Date.parse(response.startedAt);
          this.endTime = Date.parse(response.endedAt);
          this.flashsaleData = response;
        }
      }
    },
    async retrieveProduct(urlSlug: string) {
      this.flashsaleLoading = true;
      const { data, pending, error }: any = useAuthStore().authenticated
        ? await ApiService.get("/api/v1/tag/product", urlSlug, false)
        : await ApiService.get("/api/v1/tag/public/product", urlSlug, false);
      this.flashsaleLoading = pending.value;
      if (error.value) {
        this.flashsaleLoading = true;
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<ProductItem>;
        this.flashsaleProducts = response;
      }
    },
    resetFlashsaleProducts() {
      this.flashsaleProducts = [] as Array<ProductItem>;
    },
  },
});
