<template>
  <div class="sub-footer">
    <div class="container">
      <div class="row gy-3">
        <div class="col-md-6">
          <ul>
            <li class="font-dark">We accept:</li>
            <li>
              <a href="javascript:void(0)">
                <img src="/images/payment-icon/1.jpg" class="img-fluid" alt="payment icon" /></a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="/images/payment-icon/2.jpg" class="img-fluid" alt="payment icon" /></a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="/images/payment-icon/3.jpg" class="img-fluid" alt="payment icon" /></a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="/images/payment-icon/qris-icon.png" class="img-fluid" alt="payment icon" /></a>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <p class="mb-0 font-dark">
            © {{ (new Date()).getFullYear() }}, Vapebay. All Rights Reserved.
          </p>
        </div>
        <div class="col-12" v-if="runningMode == AppRunningMode.PWA">
          <p class="mb-0 font-dark">
            Version: {{ version }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AppRunningMode } from "~/contract/enum/AppRunningMode";
import { useAppStore } from "~/store/app";

const version = computed(() => useAppStore().version)
const runningMode = computed(() => useAppStore().runningMode)
</script>