import validate from "/builds/vapebay/commerce-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45loader_45global from "/builds/vapebay/commerce-frontend/middleware/authLoader.global.ts";
import route_45middleware_45global from "/builds/vapebay/commerce-frontend/middleware/routeMiddleware.global.ts";
export const globalMiddleware = [
  validate,
  auth_45loader_45global,
  route_45middleware_45global
]
export const namedMiddleware = {
  auth: () => import("/builds/vapebay/commerce-frontend/middleware/auth.ts"),
  guest: () => import("/builds/vapebay/commerce-frontend/middleware/guest.ts")
}