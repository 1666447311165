import { CategoryType } from "~/contract/enum/ResourceRelated.enum";
import axiosData from "../static/data/categoryMenuCustom.json";
import ApiService from "~/service/ApiService";
import {
  CategoryResponseDto,
  FeaturedCategoryResponseDto,
} from "~/contract/dto/Category.dto";
import {
  CategoryVo,
  FeaturedCategoryVo,
} from "~/contract/vo/CategoryRelated.vo";

export const useCategoryMenuCustomStore = defineStore("CategoryMenuCustom", {
  state: () => {
    return {
      loading: false,
      featured: [] as Array<FeaturedCategoryVo>,
      categories: [] as Array<CategoryVo>,
      data: axiosData.data,
    };
  },
  actions: {
    async fetchFeaturedCategory() {
      if(this.featured.length > 0) return this.featured 
      this.loading = true;
      const { data, pending, error, refresh }: any = await ApiService.query(
        "/api/v1/product-category/featured",
        {
          type: CategoryType.FEATURED,
        },
        true
      );
      this.loading = pending.value;
      if (error.value) {
        this.loading = true;
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<FeaturedCategoryResponseDto>;
        this.featured = response as Array<FeaturedCategoryVo>;
      }
    },
    async fetchCategory() {
      this.loading = true;
      const { data, pending, error, refresh }: any = await ApiService.query(
        "/api/v1/product-category",
        {
          type: CategoryType.CATEGORY,
        },
        true
      );
      this.loading = pending.value;
      if (error.value) {
        this.loading = true;
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<CategoryResponseDto>;
        this.categories = response as Array<CategoryVo>;
      }
    },
  },
});
