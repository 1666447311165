import { ProductBrandItem } from "~/contract/vo/ProductBrandRelated.vo";
import { ProductCategoryItem } from "~/contract/vo/ProductCategoryRelated.vo";
import { ProductVariantItem } from "~/contract/vo/ProductVariantRelated.vo";
import {
  SearchFilterRequestDto,
  SearchFilterResponseDto,
  SearchRequestDto,
} from "~/contract/dto/SearchRelated.dto";
import { TagItem } from "~/contract/vo/TagRelated.vo";
import ApiService from "~/service/ApiService";
import { useAuthStore } from "./auth";
import { ProductItem } from "~/contract/vo/ProductRelated.vo";
import {
  SearchSortBy,
  SearchTagType,
} from "~/contract/enum/SearchRelated.enum";
import { SearchItem, SearchPriceItem } from "~/contract/vo/SearchRelated.vo";
import { PageMeta } from "~/contract/vo/SearchRelated.vo";
import { toast } from "vue3-toastify";

export const useSearchStore = defineStore("search", {
  state: () => ({
    keywordParam: "",
    tagParam: "",
    pageParam: 1,
    perPageParam: 12,
    sortParam: SearchSortBy.PRODUCT_NAME_ASC,
    brandFilterSelected: [] as Array<string>,
    categoryFilterSelected: [] as Array<string>,
    variantFilterSelected: [] as Array<string>,
    priceFilterParam: null as unknown as SearchPriceItem,
    brandFilter: [] as Array<ProductBrandItem>,
    categoryFilter: [] as Array<ProductCategoryItem>,
    variantFilter: [] as Array<ProductVariantItem>,
    searchResult: [] as Array<ProductItem>,
    searchMetaResult: {
      currentPage: 1,
      itemCount: 0,
      itemPerPage: 0,
      totalItemCount: 0,
      totalPage: 1,
    } as PageMeta,
    tagResult: null as unknown as TagItem,
    searchLoading: false,
    filterLoading: false,
    tagLoading: false,
    popularSearch: [] as Array<SearchItem>,
    historySearch: [] as Array<SearchItem>,
  }),
  actions: {
    async fetchPopularSearch() {
      const { data, pending, error }: any = await ApiService.get(
        "/api/v1/search/featured",
        "",
        true
      );
      if (error.value) {
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<SearchItem>;
        this.popularSearch = response;
      }
    },
    async fetchHistorySearch() {
      if(!useAuthStore().authenticated) return
      const { data, pending, error }: any = await ApiService.get(
        "/api/v1/search/history",
        "",
        true
      );
      if (error.value) {
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<SearchItem>;
        this.historySearch = response;
      }
    },
    async retrieveFilter(requestData: SearchFilterRequestDto) {
      this.filterLoading = true;
      const { data, pending, error }: any = useAuthStore().authenticated
        ? await ApiService.post("/api/v1/search/filter", requestData, true)
        : await ApiService.post(
            "/api/v1/search/public/filter",
            requestData,
            true
          );
      this.filterLoading = pending.value;
      if (error.value) {
        this.filterLoading = true;
        return useRouter().push("/")
      }
      if (data.value && data.value.data) {
        const response = data.value.data as SearchFilterResponseDto;
        this.brandFilter = response.brand;
        this.categoryFilter = response.category;
        this.variantFilter = response.variant;
      }
    },
    async retrieveSearch(requestData: SearchRequestDto) {
      this.searchLoading = true;
      const { data, pending, error }: any = useAuthStore().authenticated
        ? await ApiService.post("/api/v1/search", requestData, true)
        : await ApiService.post("/api/v1/search/public", requestData, true);
      this.searchLoading = pending.value;
      if (error.value) {
        this.searchLoading = true;
        if(process.client) return useRouter().push("/")
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<ProductItem>;
        this.searchResult = response;
        this.searchMetaResult = data.value.meta as PageMeta;
      }
    },
    async retrieveTagInfo(humanUrlName: string) {
      if(this.tagResult && this.tagResult.humanUrlFormat == humanUrlName) return
      this.tagLoading = true;
      const { data, pending, error }: any = await ApiService.get(
        "/api/v1/tag/human-url-name",
        humanUrlName,
        false
      );
      this.tagLoading = pending.value;
      if (error?.value) {
        this.tagLoading = true;
        if(process.client) return useRouter().push("/")
      }
      if (data?.value && data?.value?.data) {
        const response = data?.value?.data as TagItem;
        this.tagResult = response;
      }
    },
    resetFilterParam() {
      this.brandFilterSelected = [];
      this.categoryFilterSelected = [];
      this.variantFilterSelected = [];
      this.priceFilterParam = null as unknown as SearchPriceItem;
    },
    resetSearchParam() {
      this.keywordParam = "";
      this.tagParam = "";
      this.pageParam = 1;
      // this.perPageParam = 12
      // this.sortParam = SearchSortBy.PRODUCT_NAME_ASC
      this.searchResult = [];
      this.searchMetaResult = {
        currentPage: 1,
        itemCount: 0,
        itemPerPage: 0,
        totalItemCount: 0,
        totalPage: 1,
      };
      this.tagResult = null as unknown as TagItem;
    },
  },
});
