<template>
  <div class="brand-logo">
    <nuxt-link to="/" class="footer-logo">
      <!-- <svg class="svg-icon">
        <use class="fill-color" xlink:href="/svg/icons.svg#logo"></use>
      </svg> -->
      <img v-if="isLightMode" src="/images/logos/logo_black.png" class="img-fluid" alt="logo" />
      <img v-if="!isLightMode" src="/images/logos/logo_white.png" class="img-fluid" alt="logo" />
    </nuxt-link>
  </div>
</template>

<script lang="ts" setup>
import { useLayout } from "~~/store/layout"


let isLightMode = computed(() => {
  return (useLayout().layoutMode || 'light') == 'light'
})
</script>