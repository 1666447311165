<template>
    <div class="col-auto">
        <ul class="border-list">
            <li>
                <a href="javascript:void(0)">
                    Register
                </a>
            </li>
            <li>
                <a href="javascript:void(0)">
                    Login
                </a>
            </li>
        </ul>
    </div>
</template>