<template>
    <Head>
        <Title>Error {{ error?.statusCode }}</Title>
    </Head>
    <MainLayout pageName="500" parent="500">
        <section class="page-not-found section-b-space">
            <div class="container">
                <div class="row gx-md-2 gx-0 gy-md-0 gy-3">
                    <div class="col-md-8 m-auto">
                        <div class="page-image">
                            <img :src="getImageUrl('illustrations/error/404.png')" class="img-fluid" alt="" v-if="error?.statusCode == 404"/>
                            <img :src="getImageUrl('illustrations/error/500.png')" class="img-fluid" alt="" v-else/>
                        </div>
                    </div>

                    <div class="col-md-8 mx-auto mt-3">
                        <div class="page-container pass-forgot" v-if="error?.statusCode == 404">
                            <div>
                                <h2>Wah, Kamu Nyasar</h2>
                                <p>
                                    Mungkin alamat tujuan kamu salah, yuk balik
                                </p>
                                <a href="/" class="btn btn-solid-default">Kembali</a>
                            </div>
                        </div>
                        <div class="page-container pass-forgot" v-else>
                            <div>
                                <h2>Oops...</h2>
                                <p>
                                    Telah terjadi kesalahan, silakan coba beberapa saat lagi
                                </p>
                                <a href="/" class="btn btn-solid-default">Kembali</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-5 bg-dark text-white" v-if="!isProduction">
                    <table>
                        <tr>
                            <td>Message</td>
                            <td>:</td>
                            <td><pre v-html="error?.message"></pre></td>
                        </tr>
                        <tr>
                            <td>Cause</td>
                            <td>:</td>
                            <td><pre v-html="error?.cause"></pre></td>
                        </tr>
                        <tr>
                            <td>Stack</td>
                            <td>:</td>
                            <td><pre v-html="error?.stack"></pre></td>
                        </tr>
                    </table>
                </div>
            </div>
        </section>
    </MainLayout>
</template>

<script lang="ts" setup>
import { NuxtError } from "nuxt/app";
import MainLayout from "~/layout/layouts/index.vue";
const props = defineProps({
    error: Object as () => NuxtError
})

useHead({
    title: `Error ${props.error?.statusCode}`
})

const isProduction = computed(() => process.env.NODE_ENV === 'production')
</script>