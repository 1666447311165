export enum SearchSortBy {
  PRODUCT_NAME_ASC = "PRODUCT_NAME_ASC",
  PRODUCT_NAME_DESC = "PRODUCT_NAME_DESC",
  LOWEST_PRICE = "LOWEST_PRICE",
  HIGHEST_PRICE = "HIGHEST_PRICE",
}

export enum SearchPromotionSortBy{
  HIGHEST_POINT = "HIGHEST_POINT",
  LOWEST_POINT = "LOWEST_POINT",
  RELEVANCE = "RELEVANCE",
}

export enum SearchTagType {
  TAG = "TAG",
  KEYWORD = "KEYWORD",
}
