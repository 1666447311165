export enum IconResourceType {
  ICON = "Icon",
  IMAGE = "Image",
}

export enum IconFeaturedCategoryType {
  ICON = "ICON",
  IMAGE = "IMAGE",
}

export enum UrlHrefType {
  INTERNAL_LINK = "INTERNAL_LINK",
  EXTERNAL_LINK = "EXTERNAL_LINK",
  TAG = "TAG",
}

export enum CategoryType {
  FEATURED = "featured",
  CATEGORY = "category",
}

export enum OrderSourceType{
  WEB = 'WEB',
  PWA = 'PWA',
}