<template>
  <footer class="footer-sm-space">
    <div class="main-footer">
      <div class="container">
        <div class="row gy-4">
          <div class="col-xl-3 col-lg-4 col-md-6">
            <div class="footer-contact">
              <brandLogo1 />
              <ul class="contact-lists">
                <li>
                  <span>
                    <b>Phone :</b>
                    <a class="font-light" :href="`tel:${companyPhone}`">{{ companyPhone }}</a>
                  </span>
                </li>
                <li>
                  <span>
                    <b>{{ useRuntimeConfig().public.const.Address }}</b>
                    <span class="font-light">{{ companyAddress }}</span>
                  </span>
                </li>
                <li>
                  <span>
                    <b>{{ useRuntimeConfig().public.const.Email }} :</b>
                    <a class="font-light" :href="`mailto:${companyEmail}`">{{ companyEmail }}</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6">
            <div class="footer-links">
              <div class="footer-title">
                <h3>Vapebay</h3>
              </div>
              <div class="footer-content">
                <ul>
                  <li>
                    <nuxt-link to="/faq" class="font-dark">FAQ</nuxt-link>
                  </li>
                  <li>
                    <nuxt-link to="/about_us" class="font-dark">Tentang Kami</nuxt-link>
                  </li>
                  <li>
                    <nuxt-link to="/terms_of_service" class="font-dark">Terms of Service</nuxt-link>
                  </li>
                  <li>
                    <nuxt-link to="/privacy_and_policy" class="font-dark">Privacy And Policy</nuxt-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6">
            <div class="footer-links">
              <div class="footer-title">
                <h3>Ikuti Kami</h3>
              </div>
              <div class="footer-content">
                <ul>
                  <li>
                    <nuxt-link :to="`https://instagram.com/${companyInstagram}`" class="font-dark">Instagram</nuxt-link>
                  </li>
                  <li>
                    <nuxt-link :to="`https://tiktok.com/${companyTiktok}`" class="font-dark">Tiktok</nuxt-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--<div :class="headings.linkClasses ? headings.linkClasses : 'col-lg-2'" v-for="(headings, index) in links"
            :key="index">
            <div class="footer-links">
              <div class="footer-title" @click="toggleFooterMenu(headings.id)">
                <h3>{{ headings.title }}</h3>
              </div>
              <div class="footer-content" :class="headings.menuOpen ? '' : 'd-none'">
                <ul>
                  <li v-for="(links, index) in headings.items" :key="index">
                    <nuxt-link :to="{ path: links.path }" class="font-dark">{{
                      links.title
                    }}</nuxt-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>-->
          <slot :class="windowSize === 'small' ? 'd-none' : ''"></slot>
        </div>
      </div>
    </div>
    <subFooter1 />
  </footer>
</template>

<script lang="ts" setup>
import { mapState } from "pinia";
import brandLogo1 from "~/layout/elements/logo/brandLogo1.vue";
import subFooter1 from "~/layout/elements/footerElements/subFooter1.vue";
import { useFooterStore } from "~~/store/footerLinks";
import { useConstantStore } from "~/store/constant";
import { ConstantKey } from "~/contract/enum/ConstantRelated.enum";

const footerStore = useFooterStore()
const constantStore = useConstantStore()

const windowWidth = ref(1500)
const windowSize = ref("large")
const clickingActive = ref(false)
const companyAddress = ref<string>("")
const companyPhone = ref<string>("")
const companyEmail = ref<string>("")
const companyTiktok = ref<string>("")
const companyInstagram = ref<string>("")

// const links = computed(() => footerStore.data)

const getWindowWidth = ():any => {
  windowWidth.value = screen.availWidth;
  if (
    screen.availWidth < 750 &&
    screen.availWidth > 575 &&
    windowSize.value != "medium"
  )
    windowSize.value = "medium";
  else if (screen.availWidth < 575 && windowSize.value != "small")
    windowSize.value = "small";
  else if (screen.availWidth > 750 && windowSize.value != "large")
    windowSize.value = "large";
}

// const toggleFooterMenu = (menuId: any):any => {
//   if (clickingActive.value) {
//     let isActive = links.value[menuId].menuOpen;
//     links.value.forEach((item, index) => {
//       links.value[index].menuOpen = false;
//     });
//     links.value[menuId].menuOpen = isActive;
//     links.value[menuId].menuOpen = !links.value[menuId].menuOpen;
//   }
// }

onMounted(async () => await nextTick(async () => {
  window.addEventListener("resize", getWindowWidth());
  companyAddress.value = await constantStore.getConstant(ConstantKey.COMPANY_ADDRESS) || ""
  companyPhone.value = await constantStore.getConstant(ConstantKey.COMPANY_PHONE) || ""
  companyEmail.value = await constantStore.getConstant(ConstantKey.COMPANY_EMAIL) || ""
  companyTiktok.value = await constantStore.getConstant(ConstantKey.COMPANY_TIKTOK) || ""
  companyInstagram.value = await constantStore.getConstant(ConstantKey.COMPANY_INSTAGRAM) || ""
}))

onBeforeUnmount(() => {
  window.removeEventListener("resize", getWindowWidth());
})

watch(()=> windowSize.value, () => {
  if (windowSize.value === "small") clickingActive.value = true;
  else clickingActive.value = false;
})

// watch(()=> clickingActive.value, () => {
//   if (!clickingActive.value) {
//     links.value.forEach((item) => (item.menuOpen = true));
//   } else {
//     links.value.forEach((item) => (item.menuOpen = false));
//   }
// })
</script>

<style></style>
