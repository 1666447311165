<template>
  <!-- <section class="breadcrumb-section section-b-space">
    <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>{{ parent }}</h3>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <nuxt-link to="/">
                  <i class="fas fa-home"></i>
                </nuxt-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ pageName }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section> -->
  <!-- {{ navigationStore.currentCategory }} -->
  <!-- {{ navigationStore.currentProduct }} -->
  <!-- {{ originalProducts }} -->
  <div class="section-b-space breadcrumb-container d-none d-sm-block">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span v-for="(item, k) in stack">
            <NuxtLink
              :to="item.href"
              :key="`breadcrumb-${k}`"
              :class="{
                'breadcrumb-btn': true,
                'breadcrumb-last-item': item.isLastItem,
              }"
              >{{ item.title }}
            </NuxtLink>
            <span v-if="!item.isLastItem" class="breadcrumb-divider">></span>
          </span>
          <!-- <pre>{{ data }}</pre> -->
          <!-- <pre>{{ originalProducts }}</pre> -->
          <!-- <pre>{{ stack }}</pre> -->
          <!-- <pre>{{ this.$route.path }}</pre> -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="section-b-space breadcrumb-container d-sm-none mx-2">
    <NuxtLink @click="previousPage" class="breadcrumb-btn">
      <i class="fas fa-arrow-left fa-1x"></i> <span>Kembali</span>
    </NuxtLink>
  </div> -->
</template>

<script lang="ts" setup>
import { mapState } from "pinia";
import { PageType } from "~/contract/enum/NavigationRelated.enum";
import { BreadcrumbItem } from "~/contract/vo/BreadcrumbItem";
import { useBreadcrumbsStore } from "~/store/breadcrumbs";
import { useCategoryMenuCustomStore } from "~/store/categoryMenuCustom";
import { useNavigationStore } from "~/store/navigation";
import { useProductStore } from "~/store/products";

const props = defineProps({
  pageName: {
    type: String,
    default: "",
  },
  parent: {
    type: String,
    default: "Parent",
  },
});

const breadcrumbsStore = useBreadcrumbsStore();
const productStore = useProductStore();
const categoryMenuStore = useCategoryMenuCustomStore();
const navigationStore = useNavigationStore();

const stack = ref<Array<BreadcrumbItem>>(breadcrumbsStore.stack);
const originalProducts = computed(() => productStore.originalProducts);
const data = computed(() => categoryMenuStore.data);

// onMounted(() => {
//   breadcrumbsStore.clearStack();
//   initStack();
// });

const fixTheLatestItem = () => {
  stack.value[stack.value.length - 1].isLastItem = true;
};

const initStack = () => {
  stack.value = []; // force clear stack
  breadcrumbsStore.clearStack();
  stack.value.push({
    title: "Home",
    href: "/",
    isLastItem: false,
  });
  if (navigationStore.currentPage == PageType.PRODUCT_DETAILS) {
    stack.value.push({
      title: "Categories",
      href: "/categories",
      isLastItem: false,
    });
    if (navigationStore.currentProduct) {
      if (navigationStore.currentProduct.category) {
        stack.value.push({
          title: navigationStore.currentProduct.category.title,
          href: `/search?tag=${navigationStore.currentProduct.category.href.value}`,
          isLastItem: false,
        });
      }
      stack.value.push({
        title: navigationStore.currentProduct.productName,
        href: `/product/${navigationStore.currentProduct.productHumanUrlName}`,
        isLastItem: false,
      });
    }
  } else if (navigationStore.currentPage == PageType.CATEGORIES) {
    stack.value.push({
      title: "Categories",
      href: "/categories",
      isLastItem: false,
    });
    // if (navigationStore.currentCategory) {
    stack.value.push({
      title: navigationStore.currentCategory.name,
      href: `/search?tag=${navigationStore.currentCategory.humanUrlFormat}`,
      isLastItem: false,
    });
    // }
  } else if (navigationStore.currentPage == PageType.DASHBOARD) {
    stack.value.push({
      title: "Dashboard",
      href: "/profile",
      isLastItem: false,
    });
  } else if (navigationStore.currentPage == PageType.ADDRESS) {
    stack.value.push({
      title: "Address",
      href: "/profile",
      isLastItem: false,
    });
  } else if (navigationStore.currentPage == PageType.USER_DETAILS) {
    stack.value.push({
      title: "Data Diri",
      href: "/profile",
      isLastItem: false,
    });
  } else if (navigationStore.currentPage == PageType.SECURITY) {
    stack.value.push({
      title: "Keamanan",
      href: "/profile",
      isLastItem: false,
    });
  } else if (navigationStore.currentPage == PageType.CART) {
    stack.value.push({
      title: "Keranjang",
      href: "/cart",
      isLastItem: false,
    });
  } else if (navigationStore.currentPage == PageType.SEARCH) {
    // alert(navigationStore.currentSearch)
    stack.value.push({
      title: "Search",
      href: `/search?q=${navigationStore.currentSearch}`,
      isLastItem: false,
    });
    stack.value.push({
      title: navigationStore.currentSearch,
      href: `/search?q=${navigationStore.currentSearch}`,
      isLastItem: false,
    });
  }
};

watch(
  [
    () => navigationStore.currentPage,
    () => navigationStore.currentProduct,
    () => navigationStore.currentSearch,
  ],
  () => {
    const currentPage = { ...navigationStore.currentPage };
    const currentProduct = { ...navigationStore.currentProduct };
    const currentSearch = { ...navigationStore.currentSearch };

    nextTick(() => {
      initStack();
      fixTheLatestItem();
    });
  }
);

// export default {
//   data() {
//     return {};
//   },
//   props: {
//     pageName: {
//       type: String,
//       default: "",
//     },
//     parent: {
//       type: String,
//       default: "Parent",
//     },
//   },
//   computed: {
//     ...mapState(useBreadcrumbsStore, ["stack"]),
//     ...mapState(useProductStore, ["originalProducts"]),
//     ...mapState(useCategoryMenuCustomStore, ["data"]),
//   },
//   mounted() {
//     this.clearStack();
//     this.initStack();
//     this.pushCategoryToStack();
//     this.pushOriginalProductsToStack();
//     this.fixTheLatestItem();
//   },
//   methods: {
//     initStack() {
//       this.stack.push({
//         title: "Home",
//         href: "/",
//         isLastItem: false,
//       });
//       this.stack.push({
//         title: "Categories",
//         href: "/categories",
//         isLastItem: false,
//       });
//     },

//     pushOriginalProductsToStack() {
//       this.originalProducts.forEach((item) => {
//         const selectedProduct: string = this.$route.path.split("/");
//         const currentProductIndex: number = parseInt(
//           selectedProduct[selectedProduct.length - 1]
//         );

//         if (item.id == currentProductIndex) {
//           const itemCategory = item.category.toLowerCase();
//           const categoryTitle =
//             itemCategory.slice(0, 1).toUpperCase() +
//             itemCategory.slice(1, itemCategory.length).toLowerCase();
//           let categoryHREF = `/search?tag=${itemCategory}`;

//           this.stack.push({
//             title: categoryTitle,
//             href: categoryHREF,
//             isLastItem: false,
//           });

//           this.stack.push({
//             title: item.name,
//             href: null,
//             isLastItem: false,
//           });
//         }
//       });
//     },

//     clearStack() {
//       const breadcrumbsStore = useBreadcrumbsStore();
//       breadcrumbsStore.clearStack();
//     },

//     pushCategoryToStack() {
//       this.data.forEach((item) => {
//         if (item.href) {
//           if (this.$route.query.tag == item.title) {
//             this.stack.push({
//               title: item.title,
//               href: item.href,
//               isLastItem: false,
//             });
//           }
//         } else {
//           item.childrens.forEach((childItem) => {
//             if (this.$route.query.tag == childItem.title.toLowerCase()) {
//               const childItemHref = childItem.href;
//               const path = childItemHref.path;

//               if (path) {
//                 let tag = "";
//                 if (childItemHref.query) {
//                   tag = childItemHref.query.tag;
//                 }
//                 const parsedHREF = `${path}?tag=${tag}`;

//                 this.stack.push({
//                   title: childItem.title,
//                   href: parsedHREF,
//                   isLastItem: false,
//                 });
//               }
//             }
//           });
//         }
//       });
//     },
//     fixTheLatestItem() {
//       this.stack[this.stack.length - 1].isLastItem = true;
//     },
//     previousPage() {
//       this.$router.go(-1);
//     },
//   },
// };
</script>
<style lang="scss" scoped></style>
