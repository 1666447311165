<template>
  <div class="top-header" :class="topBarClasses">
    <div class="container-fluid-lg">
      <div class="row">
        <div class="col-auto d-xl-block d-none">
          <ul class="border-list">
            <li>Pengguna Baru Extra Diskon 50%</li>
          </ul>
        </div>
        <div class="col-auto d-sm-block d-none" v-if="offerCode">
          <p class="font-dark-30 mb-0">
            Gunakan Kupon : <span class="fw-bold">{{ offerCode }}</span>
          </p>
        </div>
        <topBarDropDowns />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import topBarDropDowns from "~/layout/elements/headerElements/topBarDropDownCustom.vue";
export default {
  props: ["topBarClasses", "offerCode"],
  components: { topBarDropDowns },
};
</script>
