import { BreadcrumbItem } from "~/contract/vo/BreadcrumbItem";

export const useBreadcrumbsStore = defineStore("breadcrumbs", {
  state: () => ({
    stack: [] as Array<BreadcrumbItem>,
  }),
  actions: {
    pushToStack(breadcrumbItem: BreadcrumbItem) {
      this.stack.push(breadcrumbItem);
    },
    clearStack() {
      this.stack = [] as Array<BreadcrumbItem>;
    },
  },
});
