import { constants } from '../static/const/index';
import VueSlider from 'vue-slider-component';
import Vue3Toastify, { ToastContainerOptions } from 'vue3-toastify';
import { Skeletor } from 'vue-skeletor';
import vSelect from 'vue-select';
import VueDragscroll from 'vue-dragscroll';
import Countdown from 'vue3-flip-countdown';

// import View360 from "@egjs/vue3-view360";
// import Lightbox from 'vue-easy-lightbox'

// import 'vue-image-zoomer/dist/style.css';

import 'vue3-toastify/dist/index.css';

import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';

import 'vue-skeletor/dist/vue-skeletor.css';

import 'vue-select/dist/vue-select.css';

import 'vue-multiselect/dist/vue-multiselect.css';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('const', constants);
  nuxtApp.vueApp.component('VueSlider', VueSlider);
  nuxtApp.vueApp.use(VueDragscroll);
  nuxtApp.vueApp.use(Countdown);
  // nuxtApp.vueApp.use(Lightbox);
  nuxtApp.vueApp.use(Vue3Toastify, {
    theme: 'auto',
    autoClose: 3000,
  } as ToastContainerOptions);
  nuxtApp.vueApp.component('Skeletor', Skeletor);
  nuxtApp.vueApp.component('v-select', vSelect);
  nuxtApp.vueApp.component('VueMultiselect', vSelect);
  nuxtApp.vueApp.config.globalProperties.$filter = {
    truncate(text: string, length: number, clamp = '...'): string {
      var node = document.createElement('div');
      node.innerHTML = text;
      var content = node.textContent;
      if (!content) return text;
      return content.length > length ? content.slice(0, length) + clamp : content;
    },
    formatCurrency(value: string | number): string {
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });
      return formatter.format(Number(value));
    },
    formatNumber(value: string | number): string {
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(Number(value) || 0);
    },
    scaleTo100(num: number, min: number, max: number): number {
      return ((num - min) / (max - min)) * 100;
    },
  };
});
