import SecureStorageService from "~/service/SecureStorageService"
import { AppRunningMode } from "../contract/enum/AppRunningMode"
import { isMobile } from 'mobile-device-detect'

export const RUNNING_MODE_INDEX_NAME = "_RM"
export const APP_VERSION_INDEX_NAME = "_AV"

export const useAppStore = defineStore('app', {
    state: () => ({
        version: useRuntimeConfig().public.app.version,
        runningMode: AppRunningMode.BROWSER,
    }),
    actions: {
        determineRunningMode(){
            let existingRunningMode = SecureStorageService.getItem(RUNNING_MODE_INDEX_NAME)
            if(!existingRunningMode || existingRunningMode == AppRunningMode.MOBILE){
                let queryRunningMode = useRoute().query['m']
                if(queryRunningMode && Object.values(AppRunningMode).includes(queryRunningMode as unknown as AppRunningMode)){
                    existingRunningMode = queryRunningMode as AppRunningMode
                }else{
                    existingRunningMode = isMobile ? AppRunningMode.MOBILE : AppRunningMode.BROWSER
                }
            }

            if(!Object.values(AppRunningMode).includes(existingRunningMode as unknown as AppRunningMode)){
                existingRunningMode = isMobile ? AppRunningMode.MOBILE : AppRunningMode.BROWSER
            }
            
            this.runningMode = existingRunningMode as AppRunningMode
            SecureStorageService.setItem(RUNNING_MODE_INDEX_NAME, existingRunningMode as string)
        },
    }
})