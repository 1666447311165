<template>
  <div class="menu-right">
    <ul>
      <!-- <li>
        <OneSignalSubscribeButton v-if="'$OneSignal' in $nuxt && $config.public.oneSignalAppId"></OneSignalSubscribeButton>
      </li> -->
      <li>
        <button
          type="button"
          class="btn btn-spacing p-1"
          @click="toogleDarkMode"
        >
          <vue-feather type="sun" v-if="isLightMode"></vue-feather>
          <vue-feather type="moon" v-if="!isLightMode"></vue-feather>
        </button>
      </li>
      <li v-if="isAuthenticated" class="d-none d-sm-block">
        <div class="cart-media" @click="$router.push('/cart')">
          <div class="cart-icon">
            <vue-feather type="shopping-cart"></vue-feather>
            <span class="label label-theme rounded-pill">{{ cartItemCount }}</span>
          </div>
        </div>
      </li>
      <li class="onhover-dropdown account-dropdown">
        <div class="cart-media">
          <div class="cart-icon">
            <vue-feather type="user"></vue-feather>
          </div>
        </div>
        <div class="onhover-div profile-dropdown">
          <ul v-if="isAuthenticated">
            <li>Welcome, {{ user.firstName }}</li>
            <li @click="$router.push('/profile')">
              My Profile
            </li>
            <li @click="$router.push('/wishlist')">
              Wishlist
            </li>
            <li @click="$router.push('/transaction')">
              Transaksi
            </li>
            <li @click="$router.push('/preorder')">
              Pre Order
            </li>
            <li @click.prevent="logoutUser">
              Keluar
            </li>
          </ul>
          <ul v-else>
            <li>Welcome, Guest</li>
            <li @click="doLogin()">
              Masuk
            </li>
            <li @click="doRegister()">
              Daftar
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import VueFeather from "vue-feather";
// import OneSignalSubscribeButton from "~/components/OneSignalSubscribeButton.vue";
import { ProductCategoryItem } from "~/contract/vo/ProductCategoryRelated.vo";
import { ProductItem } from "~/contract/vo/ProductRelated.vo";
import { CartPayload } from "~/contract/vo/CartRelated.vo";
import animatedButton from "~/layout/elements/buttons/animatedButton.vue";
import { useAuthStore } from "~/store/auth";
import { useProfileStore } from "~/store/profile";
import { useCartStore } from "~~/store/cart";
import { useLayout } from "~~/store/layout";
import { useProductStore } from "~~/store/products";
import { useUserDashboardStore } from "~~/store/userDashboard";
import { toast } from "vue3-toastify";
import RedirectService from "~/service/RedirectService";

const isCartOpen = ref<boolean>(false);
const searchBarOpen = ref<boolean>(false);

onMounted(() =>
  nextTick(async () => {
    await useCartStore().fetchCartList();
  })
);

const cartList = computed(() => useCartStore().cartList);
// const hasCart = computed(()=>useCartStore())
const cartTotal = computed(() => useCartStore().cartTotal);
const cartItemCount = computed(() => useCartStore().cartItemCount);
const getterCartItems = computed(() => useCartStore().getCartItems);
const wishlist = computed(() => useProductStore().wishlist);
const currencySymbol = computed(() => useLayout().selectedCurrencySymbol);
const isLightMode = computed(
  () => (useLayout().layoutMode || "light") == "light"
);

const changeQuantityMixin = (event: Event, item): void => {
  const newQuantity = event.target as HTMLInputElement;
  const payload: CartPayload = {
    productId: item.id,
    diffQty: parseInt(newQuantity.value),
  };
  useCartStore().changeQuantityTo(payload);
};

const user = computed(() => useProfileStore().identity);
const isAuthenticated = computed(() => useAuthStore().authenticated);

const removeItem = (productId: string) => {
  useCartStore().removeCartItem(productId);
};
const toggleCart = () => {
  isCartOpen.value = !isCartOpen.value;
};
const toogleDarkMode = () => {
  useLayout().toggleLayoutMode();
};
const logoutUser = () => {
  RedirectService.setRedirect({ path: useRoute().fullPath, query: useRoute().query })
  useAuthStore().logout();
};

const doLogin = () => {
  RedirectService.setRedirect({ path: useRoute().fullPath, query: useRoute().query })
  useRouter().push('/auth/login')
}

const doRegister = () => {
  useRouter().push('/auth/register')
}
</script>
