import { PropType } from "nuxt/dist/app/compat/capi";
import { defineStore } from "pinia";
import { PageType } from "~/contract/enum/NavigationRelated.enum";
import { ProductItem } from "~/contract/vo/ProductRelated.vo";
import { CategoryVo } from "~/contract/vo/CategoryRelated.vo";

export const useNavigationStore = defineStore({
  id: "navigation",
  state: () => ({
    currentPage: PageType.HOME,
    currentProduct: null as unknown as ProductItem,
    currentCategory: null as unknown as CategoryVo,
    currentSearch: null as unknown as string
  }),
  actions: {
    setCurrentPage(pageType: PageType): void {
      this.currentPage = pageType;
    },
    setCurrentProduct(product: ProductItem): void {
      this.currentProduct = product;
    },
    setCurrentCategory(category: CategoryVo): void {
      this.currentCategory = category;
    },
    setCurrentSearch(search: string): void {
      this.currentSearch = search;
    },
  },
});
