import ApiService, { BaseResponse } from "~~/service/ApiService";
import { toast } from "vue3-toastify";
import { SocialSignInProvider } from "~/contract/enum/SocialSignInProvider";
import {
  SignInRequestDto,
  SignInResponseDto,
  SignUpRequestDto,
} from "~/contract/dto/AuthRelated.dto";
import SecureStorageService from "~/service/SecureStorageService";
import RedirectService from "~/service/RedirectService";
import { useHighlightStore } from "./highlight";
import { useFlashsaleStore } from "./flashsale";
// import { useOneSignalStore } from "./onesignal";

export const ACCESS_TOKEN_INDEX_NAME = "_T";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    authenticated: false,
    loading: false,
  }),
  actions: {
    async login(requestData: SignInRequestDto) {
      this.loading = true;
      const { data, pending }: any = await ApiService.post(
        "/api/v1/auth/signin",
        requestData,
        true
      );
      this.loading = pending.value;
      if (data.value && data.value.data) {
        const response = data.value.data as SignInResponseDto;
        let expDate = new Date(
          JSON.parse(atob(response.accessToken.split(".")[1]))["exp"] * 1000
        );
        const accessToken = useCookie(ACCESS_TOKEN_INDEX_NAME, {
          expires: expDate,
        });
        accessToken.value = response.accessToken; // set token to cookie
        this.authenticated = true; // set authenticated  state value to true
        useHighlightStore().resetHighlightProducts();
        useFlashsaleStore().resetFlashsaleProducts();
        useRouter()
          .push(RedirectService.getRedirect() || "/")
          .then(async () => {
            // await useOneSignalStore().login()
          });
      }
    },
    async redirectToSocialLogin(type: string) {
      if (
        !Object.values(SocialSignInProvider).includes(
          type as unknown as SocialSignInProvider
        )
      ) {
        useRouter()
          .push("/")
          .then(() => {
            toast("Gagal login dengan menggunakan Google", { type: "error" });
          });
      }
      window.location.replace(
        ApiService.parseUrl(`/web/redirect/social/${type}`)
      );
    },
    async handleSocialLoginCallback(requestData: any, type: string) {
      this.loading = true;
      if (
        !Object.values(SocialSignInProvider).includes(
          type as unknown as SocialSignInProvider
        )
      ) {
        useRouter()
          .push("/")
          .then(() => {
            toast("Gagal login dengan menggunakan Google", { type: "error" });
          });
      }
      const { data, pending }: any = await ApiService.query(
        `/api/v1/auth/social/${type}/callback`,
        requestData,
        true
      );
      this.loading = pending.value;
      if (data.value && data.value.data) {
        const response = data.value.data as SignInResponseDto;
        const accessToken = useCookie(ACCESS_TOKEN_INDEX_NAME); // useCookie new hook in nuxt 3
        accessToken.value = response.accessToken; // set token to cookie
        this.authenticated = true; // set authenticated  state value to true
        useRouter()
          .push(RedirectService.getRedirect() || "/")
          .then(async () => {
            // await useOneSignalStore().login()
          });
      }
    },
    async register(requestData: SignUpRequestDto) {
      this.loading = true;
      const { data, pending }: any = await ApiService.post(
        "/api/v1/auth/signup",
        requestData,
        true
      );
      this.loading = pending.value;
      if (data.value) {
        const response = data.value as BaseResponse;
        useRouter()
          .push("/auth/login")
          .then(() => {
            toast(response.message, { type: "success" });
          });
      }
    },
    async logout() {
      const accessToken = useCookie(ACCESS_TOKEN_INDEX_NAME); // useCookie new hook in nuxt 3
      this.authenticated = false; // set authenticated  state value to false
      accessToken.value = null; // clear the token cookie
      SecureStorageService.removeItem(ACCESS_TOKEN_INDEX_NAME);
      // await useOneSignalStore().logout()
      await useRouter().push("/auth/logout");
    },
  },
});
