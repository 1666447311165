export enum PageType {
  PRODUCT_DETAILS = "PRODUCT_DETAILS",
  HOME = "HOME",
  CATEGORIES = "CATEGORIES",
  SEARCH = "SEARCH",
  WISHLIST = "WISHLIST",
  CART = "CART",
  DASHBOARD = "DASHBOARD",
  ADDRESS = "ADDRESS",
  USER_DETAILS = "USER_DETAILS",
  SECURITY = "SECURITY",
  TRANSACTION = "TRANSACTION",
  VOUCHER = "VOUCHER",
  WALLET = "WALLET",
}
