import { RouteLocationRaw } from "#vue-router"
import SecureStorageService from "./SecureStorageService"

export const REDIRECT_TO_INDEX_NAME = "_R"

export const setRedirect = (redirectTo: RouteLocationRaw):void => {
    SecureStorageService.setItem(REDIRECT_TO_INDEX_NAME, JSON.stringify(redirectTo))
}

export const getRedirect = (): RouteLocationRaw | null => {
    let redirVal = SecureStorageService.getItem(REDIRECT_TO_INDEX_NAME)
    if(!redirVal) return null
    try {
        return JSON.parse(redirVal)
    } catch (err) {
        return ""
    }finally{
        SecureStorageService.removeItem(REDIRECT_TO_INDEX_NAME)
    }
}

export default { setRedirect, getRedirect }