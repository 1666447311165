import { toast } from "vue3-toastify";
import { ACCESS_TOKEN_INDEX_NAME, REDIRECT_TO_INDEX_NAME } from "~/store/auth";
import SecureStorageService from "./SecureStorageService";
import { RouteLocationRaw } from "#vue-router";
import RedirectService from "./RedirectService";

export interface RequestHeader {
    [key: Lowercase<string>]: string
}

export interface BaseResponse{
    code: number,
    message: string,
    error?: string,
    timestamp: number,
    responseTime: number,
}

class ApiService {
    
    private static CONFIG = {
        lazy: true,
        timeout: undefined
    }

    /**
     * @description set the default HTTP request headers
     */
    private static buildHeader() {
        const accessToken = useCookie(ACCESS_TOKEN_INDEX_NAME);

        const headers:RequestHeader = {
            "accept": "application/json",
        }
        
        if(accessToken.value){
            headers['authorization'] = `Bearer ${accessToken.value}`
        }

        return headers
    }

    public static useDefaultResponseInterceptor(){
        return ({ request, response, options }) => {
            const statusCode = response.status as number

            if(statusCode >= 400 && statusCode < 500){
                if(statusCode == 401 && process.client){
                    RedirectService.setRedirect({ path: useRoute().fullPath, query: useRoute().query })
                    useRouter().push({ path: '/auth/login' })
                }
                const resp = response._data as BaseResponse
                if(process.client) toast(resp.message, { type: "error" })
            }

            if(statusCode > 500){
                if(process.client) toast("Oops... something went wrong", { type: "error" })
            }
        }
    }

    public static parseUrl(resourcePath: string){
        let baseUrl = null
        if(process.client){
            baseUrl = useRuntimeConfig()['public']['clientHost'] as string || ""
        }else{
            baseUrl = useRuntimeConfig()['ssrHost'] as string || ""
        }
        if(baseUrl == ""){
            return resourcePath
        }
        if(resourcePath[0] == '/' || baseUrl[baseUrl.length-1] == '/'){
            return `${baseUrl}${resourcePath}`
        }
        // if(baseUrl != "" && (resourcePath[0] == '/' || baseUrl[baseUrl.length-1] == '/')){
        //     return `${baseUrl}${resourcePath}`
        // }
        return `${baseUrl}/${resourcePath}`
    }
  
    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(resource: string, params: any, client: boolean, options: any = {}) {
        return useFetch(this.parseUrl(resource), {
            method: 'GET',
            query: params,
            headers: this.buildHeader() as Record<string, string>,
            lazy: this.CONFIG.lazy,
            server: !client,
            timeout: this.CONFIG.timeout,
            onResponseError: this.useDefaultResponseInterceptor(),
            ...options
        })
    //   return ApiService.vueInstance.axios.get(resource, params);
    }
  
    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static get(resource: string, slug = "" as string, client: boolean|null, options: any = {}) {
        return useFetch(`${this.parseUrl(resource)}/${slug}`, {
            method: 'GET',
            headers: this.buildHeader() as Record<string, string>,
            lazy: this.CONFIG.lazy,
            server: !client,
            timeout: this.CONFIG.timeout,
            onResponseError: this.useDefaultResponseInterceptor(),
            ...options
        })
    //   return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
    }
  
    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post(resource: string, params: any, client: boolean, options: any = {}){
        return useFetch(`${this.parseUrl(resource)}`, {
            method: 'POST',
            headers: this.buildHeader() as Record<string, string>,
            body: params,
            lazy: this.CONFIG.lazy,
            server: !client,
            timeout: this.CONFIG.timeout,
            onResponseError: this.useDefaultResponseInterceptor(),
            ...options
        })
    //   return ApiService.vueInstance.axios.post(`${resource}`, params);
    }
  
    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static update(resource: string, slug: string, params: any, client: boolean, options: any = {}) {
        return useFetch(`${this.parseUrl(resource)}/${slug}`, {
            method: 'PUT',
            headers: this.buildHeader() as Record<string, string>,
            body: params,
            lazy: this.CONFIG.lazy,
            server: !client,
            timeout: this.CONFIG.timeout,
            onResponseError: this.useDefaultResponseInterceptor(),
            ...options
        })
    //   return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    }
  
    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(resource: string, params: any, client: boolean, options: any = {}) {
        return useFetch(`${this.parseUrl(resource)}`, {
            method: 'PUT',
            headers: this.buildHeader() as Record<string, string>,
            body: params,
            lazy: this.CONFIG.lazy,
            server: !client,
            timeout: this.CONFIG.timeout,
            onResponseError: this.useDefaultResponseInterceptor(),
            ...options
        })
    //   return ApiService.vueInstance.axios.put(`${resource}`, params);
    }
  
    /**
     * @description Send the PATCH HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static patch(resource: string, params: any, client: boolean, options: any = {}) {
        return useFetch(`${this.parseUrl(resource)}`, {
            method: 'PATCH',
            headers: this.buildHeader() as Record<string, string>,
            body: params,
            lazy: this.CONFIG.lazy,
            server: !client,
            timeout: this.CONFIG.timeout,
            onResponseError: this.useDefaultResponseInterceptor(),
            ...options
        })
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string, client: boolean, options: any = {}) {
        return useFetch(`${this.parseUrl(resource)}`, {
            method: 'DELETE',
            headers: this.buildHeader() as Record<string, string>,
            lazy: this.CONFIG.lazy,
            server: !client,
            timeout: this.CONFIG.timeout,
            onResponseError: this.useDefaultResponseInterceptor(),
            ...options
        })
    //   return ApiService.vueInstance.axios.delete(resource);
    }
  }
  
  export default ApiService;
  