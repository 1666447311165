<template>
  <div
    class="modal fade"
    id="myModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-bottom">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel">Masukkan ke Keranjang</h5>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <div class="input-group input-group-sm">
              <label for="quantity" class="fs-5 me-3">Jumlah</label>
              <div class="input-group-prepend">
                <button
                  class="btn btn-outline-secondary border-2"
                  type="button"
                  id="minus"
                  @click.prevent="removeAmount"
                >
                  -
                </button>
              </div>
              <input
                type="number"
                class="form-control border-secondary border-2"
                id="quantity"
                min="1"
                :value="amount"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary border-2"
                  type="button"
                  id="plus"
                  @click.prevent="addAmount"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="product-buttons">
            <a
              href=""
              class="btn btn-solid hover-solid btn-animation"
              @click.prevent="addToCart(product)"
              data-bs-dismiss="modal"
              >Tambah</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { CartItemType } from "~/contract/enum/CartRelated.enum";
import { ProductStockStatus } from "~/contract/enum/ProductRelated.enum";
import { ProductItem } from "~/contract/vo/ProductRelated.vo";
import { useCartStore } from "~/store/cart";
import { useProductCustomStore } from "~/store/productCustom";
const props = defineProps({
  product: {
    type: Object as PropType<ProductItem>,
  },
});
const amount = ref<number>(1);
const cartStore = useCartStore();
const addAmount = () => {
  amount.value++;
};
const successAdd = ref<boolean>(false);
onMounted(() => {
  successAdd.value = false;
});
const removeAmount = () => {
  if (amount.value > 1) {
    amount.value--;
  }
};
const productCustomStore = useProductCustomStore();

const { selectedVariantOption } = storeToRefs(productCustomStore);
const product = toRef(props, "product");
const productHasVariant = computed(() =>
  product.value && product.value.variant && product.value.variant.variantOption.length > 0 ? true : false,
);
const addToCart = (product: ProductItem | undefined) => {
  if (product) {
    const productId = product.id;
    let variantOptionId: string | null = null;
    if (product.variant) {
      variantOptionId = selectedVariantOption.value.variantOptionId;
    }

    const productVariantStockStatus: ProductStockStatus | undefined = selectedVariantOption.value?.productVariantStockStatus;
    
    let cartItemType: CartItemType;
    if (productHasVariant.value) {
      cartItemType = productVariantStockStatus == ProductStockStatus.PRE_ORDER ? CartItemType.PREORDER : CartItemType.NORMAL;
    } else {
      cartItemType = product?.productStockStatus == ProductStockStatus.PRE_ORDER ? CartItemType.PREORDER : CartItemType.NORMAL;
    }

    cartStore.addProductToCart({
      productId: productId,
      quantity: amount.value,
      variantOptionId: variantOptionId,
      cartItemType,
    });
    successAdd.value = true;
  }
};

</script>
<style scoped lang="scss">
.modal-dialog {
  position: fixed;
  top: auto;
  right: auto;
  left: auto;
  bottom: 0;
  min-height: 0;
}
</style>
