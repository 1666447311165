<template>
  <div class="search-box1 w-100 mx-lg-5 mx-md-4 mx-3" :class="[{ show: suggestions }]" @focusout="toggleSuggestion(false)"
    @focusin="toggleSuggestion(true)" style="z-index: 5">
    <div class="the-basics input-group">
      <input type="text" class="form-control typeahead" :placeholder="placeholderStr" v-model="searchQuery" aria-describedby="search-icon" @keyup.enter="onSearchBoxEnter" />
      <span class="input-group-text theme-bg-color search-box" id="search-icon" @click="onSearchBoxEnter">
        <vue-feather type="search"></vue-feather>
      </span>
    </div>
    <div class="search-suggestion container">
      <div class="row w-100 my-2" v-if="searchHelperList.length > 0">
        <div class="col-12 mb-2">
          <nav class="nav flex-column w-100 m-0">
            <nuxt-link class="nav-link w-100" v-for="(v, k) in searchHelperList" :key="`search-helper-${k}`" :to="v.to">{{
              v.text }}</nuxt-link>
          </nav>
        </div>
      </div>
      <div class="row w-100 my-2">
        <div class="col-12 mb-2">
          <b class="text-bold">Pencarian Populer</b>
        </div>
        <div class="col-12 mt-0">
          <a @mousedown="goToSearch(v)" class="badge rounded-pill suggestion-item"
            v-for="(v, k) in searchPopularList" :key="`popular-item-${k}`">{{ v?.title }}</a>
        </div>
      </div>
      <div class="row w-100 my-2" v-if="authenticated">
        <div class="col-12 mb-2">
          <b class="text-bold">Riwayat Pencarian</b>
        </div>
        <div class="col-12 mt-0">
          <a @mousedown="goToSearch(v)" class="badge rounded-pill suggestion-item"
            v-for="(v, k) in searchHistoryList" :key="`history-item-${k}`">{{ v?.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VueFeather from "vue-feather";
import { useSearchStore } from "~/store/search";
import { useAuthStore } from "~/store/auth";
import { SearchItem } from "~/contract/vo/SearchRelated.vo";
import { SearchTagType } from "~/contract/enum/SearchRelated.enum";

const suggestions = ref(false)
const searchQuery = ref("")
const searchPlaceHolderIdx = ref(0)
const searchHistoryList = computed(() => useSearchStore().historySearch || [])
const searchPopularList = computed(() => useSearchStore().popularSearch || [])
const tagResult = computed(() => useSearchStore().tagResult)
const authenticated = computed(() => useAuthStore().authenticated)
const placeholderStr = computed(() => searchPopularList.value.length > 0 ? searchPopularList.value[searchPlaceHolderIdx.value].title : "")
const searchHelperList = ref<Array<any>>([])

const toggleSuggestion = (show: boolean | null = null) => {
  if (show != null) {
    suggestions.value = show;
  } else suggestions.value = !suggestions.value;
}
const updatePlaceHolder = () => {
  setInterval(() => {
    searchPlaceHolderIdx.value =
      (searchPlaceHolderIdx.value + 1) % searchPopularList.value.length;
  }, 3000);
}
const onSearchBoxEnter = (e: any) => {
  let val = searchQuery.value;
  if (!val) return;
  toggleSuggestion(false);
  goToSearch(val);
}
const goToSearch = (v: SearchItem | string) => {
  if(typeof v !== 'string' && v.type == SearchTagType.TAG) useRouter().push({ path: "/search", query: { tag: v.indentifierValue } });
  else if(typeof v !== 'string' && v.type == SearchTagType.KEYWORD) useRouter().push({ path: "/search", query: { q: v.indentifierValue } });
  else useRouter().push({ path: "/search", query: { q: v as string } });
}

const createPossibleHelper = () => {
  searchHelperList.value = [];
  if (tagResult.value && searchQuery.value) {
    searchHelperList.value.push({
      text: `Cari ${searchQuery.value} di ${tagResult.value.name}`,
      to: {
        path: "/search",
        query: {
          tag: tagResult.value.humanUrlFormat,
          q: searchQuery.value,
        },
      },
    });
  }
}

onMounted(() => nextTick(async () => {
  await useSearchStore().fetchPopularSearch();
  if(authenticated.value) await useSearchStore().fetchHistorySearch();
}))

watch(() => searchQuery.value, function () {
  createPossibleHelper();
})
updatePlaceHolder()
</script>
