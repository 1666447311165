import moment from "moment"

export function getImageUrl(path){
    return ("/images/"+path )
}
export function getSvgUrl(path){
    return ("/svg/"+path )
}

export function getHumanDateFormat(dateStr){
    return moment(dateStr).format('DD MMM YYYY HH:mm:ss')
}
