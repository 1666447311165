import ApiService from "~/service/ApiService";
import axiosData from "../static/data/products.json";
import { useLayout } from "./layout";
import { ProductItem } from "~/contract/vo/ProductRelated.vo";
import { WishlistItemVo } from "~/contract/vo/WishlistRelated.vo";
import { WishlistResponseDto } from "~/contract/dto/WishlistRelated.dto";
import { toast } from "vue3-toastify";

export const useProductStore = defineStore({
  id: "product",
  state: () => {
    return {
      originalProducts: axiosData.data,
      data: axiosData.data,
      axiosProducts: [],
    };
  },
  getters: {
    // wishlist(state) {
    //   return state.storeWishlist;
    // },
  },
  actions: {
    setProductsData(payload) {
      this.axiosProducts = payload;
    },
    // async addToWishlist(payload) {
    //   if (isPresentInWishlist(this.storeWishlist, payload)) return true;
    //   else {
    //     this.storeWishlist.push(payload);
    //     return false;
    //   }
    // },
    currencyChanged() {
      let dataToSet = [];
      axiosData.data.forEach((item, index) => {
        dataToSet.push({
          ...axiosData.data[index],
        });
      });
      dataToSet.forEach((values) => {
        values.price = (
          values.price * useLayout().currencyConversionMultiple
        ).toFixed(2);
      });

      this.data = dataToSet;
    },
  },
});

function isPresentInWishlist(wishl, productToAdd) {
  let flag = false;
  wishl.every((item) => {
    if (item.id === productToAdd.id) {
      flag = true;
      return false;
    } else return true;
  });
  return flag;
}
