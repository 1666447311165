import { ConstantKey } from "~/contract/enum/ConstantRelated.enum";
import ApiService from "~/service/ApiService";

export const useConstantStore = defineStore("constant", {
  state: () => ({
    loading: false,
    cached: new Map<string, string>(),
  }),
  actions: {
    async getConstant(key: ConstantKey) {
      this.loading = true;
      if (this.cached.has(key)) return this.cached.get(key);
      const { data, pending, error, refresh }: any = await ApiService.get(
        "/api/v1/constant",
        key,
        true
      );
      this.loading = pending.value;
      if (error.value) {
        this.loading = false;
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as string;
        this.cached.set(key, response);
        return response;
      }
    },
  },
});
