import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import { WishlistResponseDto } from "~/contract/dto/WishlistRelated.dto";
import { WishlistItemVo } from "~/contract/vo/WishlistRelated.vo";
import ApiService from "~/service/ApiService";
import { useAuthStore } from "./auth";

export const useWishlistStore = defineStore({
  id: "wishlist",
  state: () => {
    return {
      storeWishlist: [] as Array<WishlistItemVo>,
      wishlistCount: 0 as number,
      wishlistCurrentPage: 1 as number,
      wishlistMaxPage: 1 as number,
      wishlistItemPerPage: 10 as number,
    };
  },
  actions: {
    setWishlistPage(page: number) {
      this.wishlistCurrentPage = page;
    },
    async fetchWishlist(itemPerPage: number, page: number) {
      if(!useAuthStore().authenticated) return
      const { data, pending, error }: any = await ApiService.query(
        "/api/v1/wishlist",
        {
          itemPerPage: itemPerPage,
          page: page,
        },
        true
      );
      if (error.value) {
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<WishlistResponseDto>;
        this.storeWishlist = response;
        this.wishlistCount = data.value.data.length;
        this.wishlistMaxPage = data.value.meta.totalPage;
        this.wishlistCurrentPage = page;
      }
    },
    async addToWishList(productId: string, variantOptionId: string | null) {
      if(!useAuthStore().authenticated) return useRouter().push('/auth/login')
      const { data, pending, error }: any = await ApiService.post(
        "/api/v1/wishlist",
        {
          productId: productId,
          variantOptionId: variantOptionId,
        },
        true
      );
      toast.success(data.value.message, {
        position: "top-right",
      });
    },
    async deleteWishlist(productId: string, page: number) {
      const { data, pending, error }: any = await ApiService.delete(
        `/api/v1/wishlist/${productId}`,
        true
      );
      if (error.value) {
        throw error.value;
      } else {
        await this.fetchWishlist(10, page);
        toast.success(data.value.message, {
          position: "top-right",
        });
      }
    },
    removeFromWishlist(payload) {
      this.storeWishlist.every((item, index) => {
        if (item.id === payload.id) {
          this.storeWishlist.splice(index, 1);
          localStorage.setItem(
            "wishlistItems",
            JSON.stringify(this.storeWishlist)
          );
          return false;
        } else return true;
      });
    },
  },
});
