export enum ProductStockStatus {
    AVAILABLE = 'AVAILABLE',
    ALMOST_NA = 'ALMOST_NA',
    UNAVAILABLE = 'UNAVAILABLE',
    PRE_ORDER = 'PRE_ORDER',
}
export enum ProductImageType {
    IMAGE = 'IMAGE',
    YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
    GDRIVE_VIDEO = 'GDRIVE_VIDEO'
}