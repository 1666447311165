<template>
  <NuxtLayout>
    <NuxtLoadingIndicator :height="5" color="#f32e4a" />
    <Head>
      <Title>Situs Jual Beli Vape Terlengkap, Mudah, & Aman</Title>
    </Head>
    <VitePwaManifest />
    <NuxtPage />
  </NuxtLayout>
</template>
<script lang="ts" setup>
import { useAppStore } from './store/app';
import { useAddToHomescreen } from '@owliehq/vue-addtohomescreen';
import { isIOS } from 'mobile-device-detect'
import { useLayout } from './store/layout';
import { AppRunningMode } from './contract/enum/AppRunningMode';
// import { useOneSignalStore } from './store/onesignal';

const isRunningInIos = computed(() => isIOS)
const route = useRoute()
const runtimeConfig = useRuntimeConfig()

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Vapebay` : 'Vapebay';
  },
  meta: [{ property: 'og:url', content: `${runtimeConfig.public.app.domain}${route.fullPath}` }]
})

onMounted(async () => nextTick(async () => {
  useAppStore().determineRunningMode()
  // await useOneSignalStore().requestPermission()
  if (isRunningInIos.value) {
    useAddToHomescreen({
      buttonColor: useLayout().primaryColor,
      title: "Vapebay",
      content: "Pasang aplikasi Vapebay di perangkat Anda untuk akses cepat dan mudah.",
      iconPath: "/icon-512x512.png",
      titleColor: useLayout().layoutMode == 'dark' ? '#fff' : '#000',
      contentColor: useLayout().layoutMode == 'dark' ? '#fff' : '#000',
    })
  }
}))
</script>

<style lang="scss">
.brand-logo {
  width: 10vw;
}
</style>