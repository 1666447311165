import revive_payload_client_4sVQNw7RlN from "/builds/vapebay/commerce-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/vapebay/commerce-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/vapebay/commerce-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/vapebay/commerce-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/vapebay/commerce-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/vapebay/commerce-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/vapebay/commerce-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/builds/vapebay/commerce-frontend/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import script_YrMPz5ICtR from "/builds/vapebay/commerce-frontend/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import templates_pwa_client_1fd13a5e_36cO9y4o2B from "/builds/vapebay/commerce-frontend/.nuxt/templates.pwa.client.1fd13a5e.ts";
import chunk_reload_client_UciE0i6zes from "/builds/vapebay/commerce-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugins_gZVkQZouNK from "/builds/vapebay/commerce-frontend/plugins/plugins.ts";
import bugsnag_client_TtQ636hzGt from "/builds/vapebay/commerce-frontend/plugins/bugsnag.client.ts";
import gtag_client_Zw8EQXNVTz from "/builds/vapebay/commerce-frontend/plugins/gtag.client.ts";
import onesignal_client_FpFKSicGgh from "/builds/vapebay/commerce-frontend/plugins/onesignal.client.ts";
import ssrPlugins_iHFkzvk0bT from "/builds/vapebay/commerce-frontend/plugins/ssrPlugins.ts";
import useBootstrap_client_ouDJvcVteA from "/builds/vapebay/commerce-frontend/plugins/useBootstrap.client.ts";
import vue_masonry_wall_13R6vu58Yn from "/builds/vapebay/commerce-frontend/plugins/vue-masonry-wall.ts";
import vuePlugins_NKyCNQ9bPz from "/builds/vapebay/commerce-frontend/plugins/vuePlugins.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  script_YrMPz5ICtR,
  templates_pwa_client_1fd13a5e_36cO9y4o2B,
  chunk_reload_client_UciE0i6zes,
  plugins_gZVkQZouNK,
  bugsnag_client_TtQ636hzGt,
  gtag_client_Zw8EQXNVTz,
  onesignal_client_FpFKSicGgh,
  ssrPlugins_iHFkzvk0bT,
  useBootstrap_client_ouDJvcVteA,
  vue_masonry_wall_13R6vu58Yn,
  vuePlugins_NKyCNQ9bPz
]