import { defineStore } from "pinia";
import { ProductItem } from "~/contract/vo/ProductRelated.vo";
import { useAuthStore } from "./auth";
import ApiService from "~/service/ApiService";
import { HighlightResponse } from "~/contract/dto/HighlightRelated.dto";

export const useHighlightStore = defineStore({
  id: "highlightStore",
  state: () => ({
    highlightData: [] as Array<HighlightResponse>,
    highlightLoading: false as boolean,
    highlightProducts: new Map<string, ProductItem>(),
  }),
  actions: {
    async fetchHighlightData(urlSlug: string) {
      const { data, pending, error }: any = await ApiService.get(
        "/api/v1/highlight",
        urlSlug,
        false
      );
      if (error.value) {
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<HighlightResponse>;
        response.sort((o1, o2) => o1.order - o2.order);
        this.highlightData = response;
      }
    },
    async retrieveProduct(urlSlug: string) {
      if (this.highlightProducts.has(urlSlug)) return;
      this.highlightLoading = true;
      const { data, pending, error }: any = useAuthStore().authenticated
        ? await ApiService.get("/api/v1/tag/product", urlSlug, false)
        : await ApiService.get("/api/v1/tag/public/product", urlSlug, false);
      this.highlightLoading = pending.value;
      if (error.value) {
        this.highlightLoading = true;
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<ProductItem>;
        this.highlightProducts.set(urlSlug, response);
      }
    },
    resetHighlightProducts() {
      this.highlightProducts = new Map<string, ProductItem>();
    },
  },
});
