<template>
  <header id="home" :class="{ 'nav-down': scrollDown, 'nav-up': scrollUp }">
    <!-- <topBar :offerCode="offerCode" /> -->
    <div class="main-header navbar-searchbar">
      <div class="container-fluid-lg">
        <div class="row">
          <div class="col-lg-12">
            <div class="main-menu">
              <div class="menu-left">
                <!-- <div class="toggle-nav" @click="toggleMobileNav" v-if="!hideToggleBars">
                    <i class="fa fa-bars sidebar-bar"></i>
                  </div> -->
                <div class="brand-logo d-none d-sm-block">
                  <nuxt-link to="/" @click.prevent="$router.push('/')">
                    <!-- <svg class="svg-icon">
                        <use class="fill-color" xlink:href="/svg/icons.svg#logo"></use>
                      </svg> -->
                    <img v-if="isLightMode" src="/images/logos/logo_black.png" class="img-fluid" alt="logo" />
                    <img v-if="!isLightMode" src="/images/logos/logo_white.png" class="img-fluid" alt="logo" />
                  </nuxt-link>
                  <NuxtLink class="breadcrumb-btn ms-2 d-sm-none" :style="{ display: 'none' }">
                    <i class="fas fa-arrow-left fa-2x"></i>
                  </NuxtLink>
                </div>
                <div class="brand-logo d-sm-none">
                  <nuxt-link to="/" @click.prevent="$router.push('/')" :style="{ display: logoVisible ? 'block' : 'none' }">
                    <!-- <svg class="svg-icon">
                        <use class="fill-color" xlink:href="/svg/icons.svg#logo"></use>
                      </svg> -->
                    <img v-if="isLightMode" src="/images/logos/logo_black.png" class="img-fluid" alt="logo" />
                    <img v-if="!isLightMode" src="/images/logos/logo_white.png" class="img-fluid" alt="logo" />
                  </nuxt-link>
                  <NuxtLink @click="previousPage" class="breadcrumb-btn ms-2" :style="{ display: !logoVisible ? 'block' : 'none' }">
                    <i class="fas fa-arrow-left fa-2x"></i>
                  </NuxtLink>
                </div>
              </div>
              <!-- <mainNavbar /> -->
              <!-- <menuRight @openSearchBar="toggleSearchbar" /> -->
              <!-- <form class="d-flex" role="search">
                <input class="form-control" type="search" placeholder="Search" aria-label="Search">
              </form> -->
              <!-- <div class="search-bar">
                <div class="input-group search-bar w-100">
                  <input type="text" class="form-control" :value="modelValue" @input="handleChange"
                    placeholder="Search" />
                </div>
              </div> -->
              <searchBoxCustom />
              <menuRight />
              <!-- <headerSearchBar @closeSearchBar="toggleSearchbar" :isopen="isSearchbarOpen" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script lang="ts">
import topBar from "~/layout/elements/headerElements/topBar.vue";
import categoryMenu from "~/layout/elements/headerElements/categoryMenu2.vue";
import mainNavbar from "~/layout/elements/headerElements/mainNavbar.vue";
import menuRight from "~/layout/elements/headerElements/menuSideIconsCustom.vue";
import headerSearchBar from "~/layout/elements/headerElements/headerSearchBar1.vue";
import searchBoxCustom from "~/layout/elements/headerElements/searchBoxCustom.vue";
import { useLayout } from '~~/store/layout';
import { useClickStore } from '~~/store/clickEvents';
export default {
  components: {
    topBar,
    mainNavbar,
    menuRight,
    headerSearchBar,
    categoryMenu,
    searchBoxCustom,
  },
  props: ["offerCode", "stopScrollWork"],
  data() {
    return {
      // isSearchbarOpen: false,
      headerDown: false,
      scrollTopDiff: 0,
      scrollUp: false,
      mobileView: false,
      scrollDown: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.getWindowWidth);
  },
  computed: {
    isLightMode() {
      return (useLayout().layoutMode || 'light') == 'light'
    },
    logoVisible(){
      return [
        '/',
        '/profile',
        '/profile/wallet', 
        '/profile/voucher', 
        '/profile/address', 
        '/profile/info', 
        '/profile/security', 
        '/transaction', 
        '/wishlist', 
        '/cart'
      ].includes(this.$router.currentRoute.value.path)
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.getWindowWidth);
  },
  methods: {
    // toggleSearchbar() {
    //   this.isSearchbarOpen = !this.isSearchbarOpen;
    // },
    toggleMobileNav() {
      useClickStore().toggleMobileMenu()
    },
    getWindowWidth() {
      if (screen.availWidth < 750) this.mobileView = true;
      else this.mobileView = false;
    },
    handleScroll(event) {
      if (!this.stopScrollWork) {
        if (window.scrollY > 0) {
          if (!this.headerDown) {
            this.headerDown = true;
            this.scrollDown = false;
            this.scrollUp = false;
          }
          // if (window.scrollY > this.scrollTopDiff) {
          //   this.scrollDown = true;
          //   this.scrollUp = false;
          // } else {
          //   this.scrollUp = true;
          // }
            this.scrollDown = true;
            this.scrollUp = true;
          this.scrollTopDiff = window.scrollY;
        } else if (window.scrollY === 0) {
          this.headerDown = false;
          this.scrollDown = false;
          this.scrollUp = false;
        }
      }
    },
    previousPage() {
      this.$router.go(-1);
    },
  },
};
</script>
  
<style lang="scss" scoped>
.toggle-nav {
  margin-right: 15px;
}
</style>