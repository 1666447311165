<template>
  <div>
    <MainHeader :offerCode="offerCode" />
    <breadcrumb1 v-if="breadcrumb" :parent="parent" :pageName="pageName" />
    <slot v-else name="breadcrumb"> </slot>
    <slot></slot>
    <footer1>
      <slot name="footerRight"></slot>
    </footer1>
    <mobileMenu/>
  </div>
</template>

<script lang="ts">
import MainHeader from "~/layout/common/headers/index.vue";
import footer1 from "~/layout/common/footers/footer1.vue";
import mobileMenu from "~/layout/elements/navigation/mobileMenu.vue";
import breadcrumb1 from "~/layout/elements/breadcrumbs/breadcrumb1.vue";

export default {
  props: {
    offerCode: {
      type: String,
    },
    pageName: {
      type: String,
    },
    parent: {
      type: String,
    },
    breadcrumb: {
      type: Boolean,
      default: false,
    },
  },
  components: { MainHeader, footer1, mobileMenu, breadcrumb1 },
};
</script>
