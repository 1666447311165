export const constants = {
  comparePagePath: "/page/compare",
  cartPagePath: "/cart",
  wishlistPagePath: "/wishlist",
  CommonImagePath: "@/assets/images/",
  RawHamColorblockTshirt: "Raw Ham Colorblock T-shirt",
  Review: "5040 Review",
  Price: "$49.55",
  Size: "Size",
  Sizes: ["S", "M", "L", "XL", "XXL"],
  Color: "Color",
  Black: "Black",
  White: "White",
  Blue: "Blue",
  Gray: "Gray",
  Qty: "Qty",
  Quantity: [1, 2, 3, 4],
  Addtocart: "Keranjang",
  AddedToBag: "Sudah di keranjang",
  sharewith: "share with",
  Sale: "Sale",
  NewTrandingFashion: "New Tranding Fashion",
  BUYONEGETONE: "BUY ONE GET ONE",
  FREE: "FREE",
  Discover: "Discover",
  OurCollection: "Koleksi Kami",
  OurCategory: "Our Category",
  NewArrival: "New Arrival",
  Newoffers: "New Offers",
  JustForYou: "Just For You",
  InstagramShop: "Instagram Shop",
  NewCollection: "New Collection",
  LatestProduct: "Produk Terkini",
  OurProduct: "Our Product",
  Mostpopularss: "Most Popular",
  OurUpdate: "Our News & Update",
  OurBlog: "Our Blog",
  Buttons: "Button",
  SubscribeOurNews: "Ikuti Kami",
  SubscribeDescription: "Ikuti dan dapatkan produk dan promo terkini seputar vape",
  ShopNow: "Shop Now",
  NEW: "New",
  OFF: "Off",
  Next: "Next",
  Prev: "Prev",
  HurryUp: "Hurry up!",
  SpecialOffer: "Special Offer",
  Showdetails: "Show details",
  LearnMore: "Learn more",
  VRCollection: "VR Collection",
  Share: "Share",
  BUYNOW: "BUY NOW",
  Discount: "Discount",
  FreshFruits: "Fresh Fruits",
  OurNewsUpdate: "Our News & Update",
  ReadMore: "Read More",
  Allcategories: "All categories",
  CategoryList: "Category List",
  pagenotfound: "Wah, Kamu Nyasar",
  pagedescription: "Mungkin alamat tujuan kamu salah, yuk balik",
  BackHomePage: "Kembali",
  Logins: "Login",
  Username: "Username",
  Pleasefillthename: "Please fill the name",
  Password: "Kata Sandi",
  Forgotyourpassword: "Forgot your password?",
  LogIn: "Masuk ke akunmu",
  Orsigninwith: "Atau lanjutkan dengan",
  Facebook: "Facebook",
  Google: "Google",
  Notamember: "Not a member?",
  Signupnow: "Sign up now",
  Registers: "Register",
  Name: "Name",
  EmailAddress: "Email Address",
  ConfirmPassword: "Confirm Password",
  SignUp: "Sign Up",
  Orsignupwith: "Or sign up with",
  Alreadyhaveanaccount: "Sudah punya akun?",
  ForgotPassword: "Forgot Password",
  EnterEmailAddress: "Enter Email Address",
  Send: "Send",
  cartDescription: "Your cart will be expired in",
  minutes: "minutes !",
  CheckOut: "Check Out",
  clearallitems: "Kosongkan Keranjang",
  ContinueShopping: "Lanjutkan Berbelanja",
  ApplyCoupon: "Gunakan Kupon",
  CartTotals: "Ringkasan Tagihan",
  TotalMRP: "Subtotal",
  CouponDiscount: "Diskon Kupon",
  ConvenienceFee: "Convenience Fee",
  ProcessCheckout: "Process Checkout",
  Promocode: "Promocode",
  EXAMPLECODE: "EXAMPLECODE",
  TotalUSD: "Total (USD)",
  Yourcart: "Your cart",
  Country: "Country",
  CountryArr: [
    "United States",
    "India",
    "America",
    "South America",
    "Dubai",
    "Hong Kong",
    "Indonesia",
    "Pakistan",
    "Saudi Arabia",
    "China",
  ],
  Choose: "Choose...",
  State: "State",
  StateArr: [
    "Gujarat",
    "Maharashtra",
    "Goa",
    "Dehli",
    "Madya Pradesh",
    "Uttar Pradesh",
    "Himacal Pradesh",
    "Rajesthan",
  ],
  Payment: "Payment",
  FirstName: "First Name",
  LastName: "Last Name",
  SaveInfo: "Save this information for next time",
  Creditcard: "Credit card",
  Debitcard: "Debit card",
  PayPal: "PayPal",
  Nameoncard: "Name on card",
  Fullname: "Full name as displayed on card",
  Creditnumber: "Credit card number",
  Creditnumberrequired: "Credit card number is required",
  Expiration: "Expiration",
  CVV: "CVV",
  Continuecheckout: "Continue to checkout",
  Wearecomingsoon: "We are coming soon",
  ComingDescription: "we are almost there! if you want to get notified when the website goes live, subscribe to our mailing list!",
  NotifyMe: "Notify Me!",
  Address: "Address :",
  ActualAddress: " Jl. Tj. Duren Barat No.5A, RT.10/RW.4, Tj. Duren Utara, Kec. Grogol petamburan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11470",
  PhoneNumber: "Phone Number :",
  num1: "+ 185659635",
  num2: "+ 658651167",
  ThemeEmail: "voxo123@gmail.com",
  ThemeEmail2: "voxo158@gmail.com",
  LetTouch: "Let's get in touch",
  SuggestionDesc: "We're open for any suggestion or just to have a chat",
  Submit: "Kirim",
  ConfirmEmail: "Confirm Email",
  Comment: "Comment",
  Email: "Email",
  EmailRequired: "Your email address will not be published. Required fields are marked *",
  Contactus: "Contact Us",
  Howhelp: "How can we help you?",
  OrderSuccess: "Order Success",
  PaymentDescription: "Payment Is Successfully Processsed And Your Order Is On The Way",
  TransactionID: "Transaction ID:267676GHERT105467",
  OrderDetail: "Order Details",
  summery: "summery",
  OrderID: "Order ID: 5563853658932",
  OrderDate: "Order Date: October 22, 2018",
  OrderTotal: "Order Total: $907.28",
  shippingaddress: "shipping address",
  Add1: "Gerg Harvell",
  Add2: "568, Suite Ave.",
  Add3: "Austrlia, 235153 Contact No. 48465465465",
  paymentmethod: "payment method",
  PayonDelivery: "Pay on Delivery (Cash/Card). Cash on delivery (COD) available. Card/Net banking acceptance subject to device availability.",
  expecteddate: "expected date of delivery:",
  fulldate: "october 22, 2018",
  trackorder: "track order",
  SearchForProducts: "Search For Products",
  ShowMenu: "Show Menu",
  Category: "Kategori",
  PopularPosts: "Popular Posts",
  Johnwick: "John wick",
  date: "15 Aug 2021",
  LeaveComments: "Leave Comments",
  Comments: "Comments",
  Blogtitle: "Just a Standard Format Post.",
  BlogDesp1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum has been the industry'sstandard dummy text ever since the 1500s.looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage.",
  BlogDesp2: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latinprofessor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classicalliterature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproducedin their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.",
  BlogDesp3: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normaldistribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum astheir default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes onpurpose injected humour and the like.",
  loadmore: "Load more",
  NoProduct: "No More Product",
  ElementButton: "Element Button",
  ButtontextSizes: "Button text Sizes",
  Bootstrapbutton: "Bootstrap button",
  ButtonOutlines: "Button Outline",
  Blocklevelbutton: "Block level button",
  Buttonblock: "Button block",
  Activelink: "Active link",
  Links: "Link",
  Buttongroups: "Button group",
  Category1: "Category Style 1",
  Category2: "Category Style 2",
  Category3: "Category Style 3",
  Collection1: "Collection Banner 1",
  Collection2: "Collection Banner 2",
  Collection3: "Collection Banner 3",
  Collection4: "Collection Banner 4",
  Collection5: "Collection Banner 5",
  Collection6: "Collection Banner 6",
  Collection7: "Collection Banner 7",
  Deal1: "Deal Banner 1",
  Deal2: "Deal Banner 2",
  Deal3: "Deal Banner 3",
  Deal4: "Deal Banner 4",
  Product1: "Product Style 1",
  Product2: "Product Style 2",
  Product3: "Product Style 3",
  Product4: "Product Style 4",
  Product5: "Product Style 5",
  Product6: "Product Style 6",
  All: "All",
  App: "App",
  Card: "Card",
  Web: "Web",
  ShopTheLatestTrends: "Shop The Latest Trends",
  shopdescription: "Shop the latest clothing trends with our weekly edit of what's new in online at Voxo. From out latest woman collection.",
  bannerdescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  Filter: "Filter",
  Close: "Tutup",
  HideFilter: "Hide Filter",
  ShowFilter: "Show Filter",
  Latestfilter: "Latest filter",
  Brand: "Brand",
  SpecifyDescription: "The Model is wearing a white blouse from our stylist's collection, see the image for a mock-up of what the actual blouse would look like.it has text written on it in a black cursive language which looks great on a white color.",
  ProductDescription1: "Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him          some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure.",
  ProductQuestion1: "Give you a complete account of the system",
  fabric: "fabric",
  ProductDescription2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab, autem nemo? Tempora vitae assumenda laudantium unde magni, soluta repudiandae quam, neque voluptate deleniti consequatur            laboriosam veritatis? Tempore dolor molestias voluptatum! Minima possimus, pariatur sed, quasi provident dolorum unde molestias, assumenda consequuntur odit magni blanditiis obcaecati? Eacorporis odit dolorem fuga, fugiat soluta consequuntur magni.",
  ProductDescription3: "Art silk is manufactured by synthetic fibres like rayon. It's light in weight and is soft on the skin for comfort in summers.Art silk is manufactured by synthetic fibres like rayon. It's light in weight and is soft on the skin for comfort in summers.",
  ProductDescription4: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  Customerreviews: "Customer Reviews",
  numRatings: "82 Ratings",
  Rating: "Rating",
  CustomersAlsoBoughtThese: "Produk Sering Dibeli Bersama",
  LTR: "LTR",
  RTL: "RTL",
  Prices: "Harga",
  DiscountRange: "Discount Range",
  VoxoPlus: "VOXO PLUS",
  EnjoyExperience: "Enjoy app-like experience",
  EnjoyExperienceDescription: "With this Screen option you can use Website like an App.",
  ADDTOHOMESCREEN: "ADD TO HOMESCREEN",
  wishlist: "Wishlist",
  VIEWCART: "TAMPILKAN KERANJANG",
  English: "English",
  Franch: "Franch",
  Spanish: "Spanish",
  ProceedTOPayment: "Proceed to checkout",
  Total: "Total",
  SuccessAddtocart: " berhasil ditambahkan ke keranjang",
  TOTAL: "TOTAL",
  orderIn: " order dalam 24 jam terakhir",
  activeView: " sedang melihat produk",
  ProductName: "Brand New t-Shirt",
  BestSeller: "#1 Best seller",
  infashion: "dalam Vape",
  selectsize: "select size",
  sizechart: "size chart",
  pleaseselectsize: "please select size",
  quentityname: "quantity",
  Days: "Days",
  Hour: "Hour",
  Min: "Min",
  Sec: "Sec",
  productdetail: "product details",
  Tags: "Tags",
  Viewdetails: "View details",
  CustomerRating: "Customer Rating",
  GenericName: "Generic Name",
  Department: "Department",
  Manufacturer: "Manufacturer",
  DateFirstAvailable: "Date First Available",
  image: "image",
  productname: "product name",
  price: "price",
  availability: "availability",
  action: "action",
  whoAreWe: "WHO ARE WE",
  largerFashion: "largest Online fashion destination",
  aboutUsDesc: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam,
  culpa! Asperiores labore amet nemo ullam odit atque adipisci,
  hic, aliquid animi fugiat praesentium quidem. Perspiciatis,
  expedita!`,
  aboutUsDesc2: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam,
  culpa! Asperiores labore amet nemo ullam odit atque adipisci,
  hic, aliquid animi fugiat praesentium quidem. Perspiciatis,
  expedita!`,
  meetOurTeam: "Meet Our Team",
  teamDesc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero assumenda hic porro odio voluptas qui quod sed.",
};