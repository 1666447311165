import HashHmacSHA256 from 'crypto-js/hmac-sha256'
import uuidv4 from 'uuid-browser/v4'

const UUID_KEY_IDX = "UUID_KEY_STORE"

export const getUuid = ():string => {
    let key = ""
    let result = localStorage.getItem(HashHmacSHA256(UUID_KEY_IDX, UUID_KEY_IDX) as unknown as string)
    if(result == null){
        key = uuidv4()
        localStorage.setItem(HashHmacSHA256(UUID_KEY_IDX, UUID_KEY_IDX) as unknown as string, btoa(key))
    }else{
        key = atob(result)
    }
    return key
}

export const getItem = (key: string): string | null => {
    let result = localStorage.getItem(HashHmacSHA256(key, getUuid()) as unknown as string)
    if(result != null) return atob(result)
    else return result
}

export const setItem = (key: string, value: string) => {
    localStorage.setItem(HashHmacSHA256(key, getUuid()) as unknown as string, btoa(value))
}

export const removeItem = (key: string) => {
    return localStorage.removeItem(HashHmacSHA256(key, getUuid()) as unknown as string)
}

export default { getItem, setItem, removeItem, getUuid }