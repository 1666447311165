import {UserDataDiriPayloadDto, UserProfileResponseDto, CompleteRegistrationRequestDto} from "~/contract/dto/ProfileRelated.dto";
import {UserIdentityDetailVo, UserIdentityVo} from "~/contract/vo/ProfileRelated.vo";
import ApiService, { BaseResponse } from "~/service/ApiService";
import { useAuthStore } from "./auth";
import { toast } from "vue3-toastify";

export const useProfileStore = defineStore('profile', {
    state: () => ({
        identity: {} as UserIdentityVo,
        detailIdentity: {} as UserIdentityDetailVo,
        loading: false,
    }),
    actions: {
        async retrieveIdentity() {
            this.loading = true
            const { data, pending, error, refresh }: any = await ApiService.get('/api/v1', 'profile', true)
            this.loading = pending.value;
            if(error.value) throw error.value
            if (data.value && data.value.data) {
                const response = data.value.data as UserProfileResponseDto
                this.identity = response as UserIdentityVo;
                return response as UserIdentityVo;
            }
            return null
        },
        async fetchDetailIdentity() {
            const { data, pending, error, refresh }: any = await ApiService.get('/api/v1/profile', 'detail', true)
            if(error.value) throw error.value
            if (data.value && data.value.data) {
                this.loading = true
                const response = data.value.data as UserIdentityDetailVo
                this.detailIdentity = response as UserIdentityDetailVo;
                return response as UserIdentityDetailVo;
            }
            return null
        },
        async updateProfile(payload: UserDataDiriPayloadDto) {
            const { data, pending, error, refresh }: any = await ApiService.put('/api/v1/profile', payload, true)
            if(error.value) throw error.value
            this.fetchDetailIdentity();
        },
        async deleteProfile() {
            const { data, pending, error, refresh }: any = await ApiService.delete('/api/v1/profile', true)
            if(error.value) throw error.value
            await useAuthStore().logout();
        },
        async finishRegistration(requestData: CompleteRegistrationRequestDto) {
            this.loading = true
            const { data, pending, error }: any = await ApiService.put('/api/v1/profile/finish', requestData, true)
            this.loading = pending.value;
            if(error.value){
                if(error.value.data.code == 403){
                    return useAuthStore().logout().then(() => {
                        toast(error.value.data.message, { type: "warning" })
                    })
                }
            }
            if (data.value) {
                const response = data.value as BaseResponse
                useRouter().push('/').then(() => {
                    toast(response.message, { type: "success" })
                })
            }
        },
    },
});